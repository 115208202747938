import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaDefault({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section
      data-component="CtaDefault"
      id={fields.sectionId ?? undefined}
      className={clsx(fields.containerWidth === 'full' ? 'bg-eo-buizel' : 'bg-transparent', 'relative mt-8 mb-12 lg:mt-16 lg:mb-24')}
    >
      <div className="relative container">
        <div className="bg-eo-buizel rounded-2xl flex flex-col sm:flex-row items-center justify-between md:px-7 lg:px-24 py-9 lg:py-8 xl:mx-24">
          <Content className="text-white max-lg:text-center children-headings:text-2xl lg:children-headings:text-3xl mb-4 sm:mb-0 sm:w-2/3 max-sm:px-4">
            {fields.description}
          </Content>
          <div className="sm:w-1/3 flex justify-end">
            <LosseLink src={fields.link} className="btn--blue" />
          </div>
        </div>
      </div>
    </section>
  )
}
