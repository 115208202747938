import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Microcopy from '~/components/elements/Microcopy'
import type { Solution } from '~/graphql/types'
import type { Page_Flexcontent_Flex_Solutions, Maybe } from '~/graphql/types'

export default function SolutionsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Solutions }) {
  const [currentPostIndex, setCurrentPostIndex] = useState(-1)

  const customInsertableHighlightedItemsLength = fields.customInsertableHighlightedItems?.length ?? 0
  const postsLength = fields.posts?.edges.length ?? 0

  return (
    <section data-component="SolutionsHighlighted" id={fields.sectionId ?? undefined} className="py-12 lg:py-24">
      <div className="container">
        <div className="flex justify-center">
          <Content className="children-headings:text-3xl lg:children-headings:text-7xl text-eo-whiscash">{fields.description}</Content>
        </div>
        <div
          className={clsx(
            customInsertableHighlightedItemsLength + postsLength === 2 ? 'grid-cols-2 sm:w-2/3 mx-auto' : 'grid-cols-3',
            'grid mt-6 lg:mt-12 gap-x-6 gap-y-6 lg:gap-y-12'
          )}
        >
          {fields.customInsertableHighlightedItems &&
            fields.customInsertableHighlightedItems.map((edge, index) => {
              const post = {
                title: edge?.title || '',
                uri: edge?.link?.url,
                featuredImage: {
                  node: edge?.image
                },
                recap: {
                  resume: edge?.resume
                }
              } as Solution

              return (
                <SolutionPost
                  key={post.title}
                  readLessText="Inklappen"
                  readMoreText={edge?.link?.title}
                  post={post}
                  setCurrentPostIndex={setCurrentPostIndex}
                  expanded={currentPostIndex === index}
                  index={index}
                />
              )
            })}
          {fields.highlighted &&
            fields.highlighted?.map((edge, index) => {
              const post = edge as Solution

              return (
                <SolutionPost
                  key={post.title}
                  readLessText="Inklappen"
                  readMoreText={fields.readMoreText}
                  post={post}
                  setCurrentPostIndex={setCurrentPostIndex}
                  expanded={currentPostIndex === index}
                  index={index}
                />
              )
            })}
          {fields.showCta && (
            <div className="col-span-3 mb-24 mt-16">
              <div className="bg-eo-buizel rounded-2xl shadow-lg grid grid-cols-6 p-9">
                <div className="col-span-1 relative">
                  <LossePlaatjie maxwidth={500} src={fields.cta?.image} className="absolute -top-1/2" />
                </div>
                <div className="col-span-3 flex items-center">
                  <Content className="text-white px-12">{fields.cta?.description}</Content>
                </div>
                <div className="col-span-2 flex items-center">
                  <LosseLink src={fields.cta?.link} className="btn--blue" />
                </div>
              </div>
            </div>
          )}
        </div>

        {(fields.microcopy || fields.link) && (
          <div className="mt-20 flex flex-col items-center justify-center">
            {fields.microcopy && (
              <div>
                <Microcopy text={fields.microcopy} />
              </div>
            )}
            {fields.link && <LosseLink src={fields.link} className="btn--blue" />}
          </div>
        )}
      </div>
    </section>
  )
}

function SolutionPost({
  post,
  readMoreText,
  readLessText,
  setCurrentPostIndex,
  expanded,
  index
}: {
  post: Solution
  readMoreText?: Maybe<string | undefined>
  readLessText?: Maybe<string | undefined>
  setCurrentPostIndex: Dispatch<SetStateAction<number>>
  expanded: boolean
  index: number
}) {
  const excludeLink = post.advancedPosttypeSettings?.excludePageFromSite

  return (
    <div className="col-span-3 h-fit lg:col-span-1 flex flex-col items-center shadow-lg bg-white">
      {excludeLink ? (
        <div className="p-4 lg:p-8">
          <div className="flex items-center mb-6">
            {post.featuredImage && (
              <LossePlaatjie maxwidth={96} src={post.featuredImage?.node} className="h-16 w-16 lg:w-24 lg:h-24 object-contain" />
            )}
            <h3 className="text-xl ml-4">{post.title}</h3>
          </div>

          {expanded ? <Content>{post.recap?.resume}</Content> : <Content className="line-clamp-3">{post.recap?.resume}</Content>}

          <div className="flex justify-end mt-4 lg:mt-10">
            <button
              className={clsx('btn--blue--transparent', expanded && 'after:-rotate-90 after:transition-transform')}
              onClick={() => {
                if (expanded) {
                  setCurrentPostIndex(-1)
                } else {
                  setCurrentPostIndex(index)
                }
              }}
            >
              {expanded ? readLessText : readMoreText}
            </button>
          </div>
        </div>
      ) : (
        <LosseLink to={post.uri} className="p-4 lg:p-8">
          <div className="flex items-center mb-6">
            <LossePlaatjie maxwidth={96} src={post.featuredImage?.node} className="h-16 w-16 lg:w-24 lg:h-24 object-contain" />
            <h3 className="text-xl ml-4">{post.title}</h3>
          </div>
          <Content className="line-clamp-3">{post.recap?.resume}</Content>
          <div className="flex justify-end mt-4 lg:mt-10">
            <span className="btn--blue--transparent">{readMoreText}</span>
          </div>
        </LosseLink>
      )}
    </div>
  )
}
