import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Microcopy from '~/components/elements/Microcopy'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImage" id={fields.sectionId ?? undefined} className="py-6 lg:py-12">
      <div className="container">
        <div className="grid grid-cols-2 items-center gap-4 lg:gap-7">
          <div className={clsx(!fields.reverseColumns && 'lg:order-last', 'col-span-2 lg:col-span-1')}>
            <LossePlaatjie
              maxwidth={900}
              src={fields.image}
              className={clsx(
                fields.roundImage && 'rounded-full',
                'max-w-[75%] md:max-lg:max-w-[50%] mx-auto lg:max-w-[90%] max-sm:max-h-[225px] lg:max-h-[550px] object-contain'
              )}
            />
            {fields.image?.caption && (
              <Content className="max-w-[90%] mx-auto text-center mt-5 children:text-sm">{fields.image.caption}</Content>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1">
            <Content
              className={clsx(
                fields.titleColor === 'orange' && 'children-h2:text-eo-buizel',
                fields.titleColor === 'blue' && 'children-h2:text-eo-whiscash',
                fields.titleColor === 'gray' && 'children-h2:text-eo-beautifly',
                fields.titleFontsize === 'default' &&
                'children-h3:text-lg lg:children-h3:text-xl children-h2:text-xl sm:children-h2:text-2xl lg:children-h2:text-3xl',
                fields.titleFontsize === 'default' &&
                fields.titleFontfamily !== 'montserrat' &&
                'lg:children-h2:!text-5xl lg:children-h2:pr-12 lg:children-h2:!leading-[1.2]',

                fields.titleFontsize === 'large' &&
                'children-h2:text-4xl lg:children-h2:text-6xl children-h3:text-eo-stakataka children-h3:text-2xl lg:children-h3:text-4xl',
                fields.titleFontfamily === 'montserrat' &&
                'children-headings:!mb-1 children-headings:!font-montserrat children-headings:!font-semibold children-strong:!font-semibold',
                'children-headings:mb-3 lg:children-headings:mb-12'
              )}
            >
              {fields.description}
            </Content>
            {fields.links?.map((edge) => {
              return (
                <div
                  key={edge?.link?.title}
                  className="flex flex-col items-center lg:items-start justify-center lg:justify-start mt-7 lg:mt-14"
                >
                  <div>
                    <LosseLink className="btn--blue" key={edge?.link?.title} src={edge?.link} />
                  </div>
                  {edge?.microcopy && <Microcopy className="lg:!text-left lg:pr-40" text={edge?.microcopy} />}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
