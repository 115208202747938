import type { ComponentPropsWithoutRef } from 'react'
import { LosseContent, losseContentToReact } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'

export default function Content({ ...rest }: ComponentPropsWithoutRef<'div'>) {
  return (
    <LosseContent
      {...rest}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        if (name === 'a' && attribs.type === 'pdf') {

          // replace '/_asset?url=/' with 'https://admin.e-office.com/
          const url = attribs.href.replace('/__asset?url=/', 'https://admin.e-office.com/')

          return (
            <a href={url} target="_blank" rel="noopener noreferrer">{losseContentToReact(children)}</a>
          )
        }

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attribs.class === 'button') {
            return (
              <Button to={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return null
      }}
    >
      {rest.children}
    </LosseContent>
  )
}
