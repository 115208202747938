import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Services, Service } from '~/graphql/types'

export default function ServicesOverview({ fields }: { fields: Page_Flexcontent_Flex_Services }) {
  return (
    <section data-component="ServicesOverview" id={fields.sectionId ?? undefined} className="py-12">
      <div className="container">
        <div className="grid grid-cols-2 lg:mt-20 gap-x-6 gap-y-12">
          {fields.posts?.edges.map((edge) => {
            const post = edge.node as Service

            return (
              <div key={post.title} className="col-span-2 lg:col-span-1 rounded-2xl shadow-lg bg-white">
                <LosseLink to={post.uri} className="flex flex-col px-4 lg:px-8 pb-6 lg:pb-10">
                  <div className="flex items-center -mt-2">
                    <LossePlaatjie
                      maxwidth={128}
                      src={post.featuredImage?.node}
                      className="mb-4 w-14 h-20 lg:w-32 lg:h-40 object-cover rounded-b-lg lg:rounded-b-xl"
                    />
                    <h2 className="text-eo-ledian max-lg:leading-6 text-lg lg:text-3xl px-7 mb-6 max-lg:pt-3">{post.title}</h2>
                  </div>
                  <div className="px-4 py-2 lg:px-16 lg:py-5">
                    <Content className="line-clamp-4">{post.recap?.resume}</Content>
                  </div>
                  <div className="flex justify-end">
                    <span className="btn--blue--transparent">{fields.readMoreText}</span>
                  </div>
                </LosseLink>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
