import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import Modal from '~/components/elements/Modal'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaInBlockForm({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [formStatus, setFormStatus] = useState('')

  return (
    <>
      <section data-component="CtaInBlockForm" id={fields.sectionId ?? undefined}>
        <div className="container">
          <div className="grid grid-cols-2 items-center lg:gap-20 lg:pl-12">
            <div className={clsx(fields.reverseColumns && 'order-last', 'col-span-2 lg:col-span-1 flex flex-col items-center')}>
              <LossePlaatjie maxwidth={750} src={fields.image} className="relative z-[2] max-h-[300px] w-auto" />
              <div className="bg-white shadow-lg rounded-3xl px-4 lg:px-20 pt-32 pb-8 lg:pb-16 -mt-28 flex flex-col items-center">
                <Content className="children-headings:text-2xl children-headings:text-eo-buizel lg:children-headings:px-10 children-headings:mb-6">
                  {fields.formFields?.description}
                </Content>
                <button onClick={() => setModalOpen(true)} className="btn--blue mt-7">
                  {fields.formFields?.buttonText}
                </button>
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1 max-lg:mt-8">
              <Content className="children-headings:!font-montserrat children-headings:text-2xl children-headings:font-semibold">
                {fields.description}
              </Content>
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={modalOpen} requestClose={() => setModalOpen(false)}>
        <Content className="lg:children-headings:text-4xl children-headings:mb-12">{fields.formFields?.popup?.description}</Content>
        <Form
          className="form-modal"
          data={fields.formFields?.popup?.form}
          renderLoading={() => <Loading />}
          renderStatus={({ status, content }) => {
            setFormStatus(status)

            return <Content>{content}</Content>
          }}
        />
      </Modal>
    </>
  )
}
