import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import { useState } from 'react'
import Content from '~/components/elements/Content'
import Microcopy from '~/components/elements/Microcopy'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentAccordion({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [activeIndex, setActiveIndex] = useState<number | null>(-1)

  function _setActiveIndex(index: number) {
    if (activeIndex === index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  return (
    <section data-component="ContentAccordion" id={fields.sectionId ?? undefined} className="py-16 lg:py-32">
      <div className="container">
        <div className="sm:w-2/3 mx-auto">
          <Content className="children-h3:text-eo-stakataka lg:children-h3:text-2xl children-h2:text-3xl lg:children-h2:text-7xl children-h2:text-eo-buizel children-headings:mb-4 lg:children-headings:mb-10">
            {fields?.description}
          </Content>
        </div>

        {fields?.showAlternative ? (
          <div className="lg:w-3/4 mx-auto">
            {fields?.items?.map((edge, index) => (
              <div key={edge?.title} className="bg-white shadow-md lg:shadow-lg mb-10 rounded-2xl overflow-hidden">
                <div className="grid grid-cols-4 p-6 lg:px-20 lg:py-10">
                  <div className="col-span-4">
                    <div
                      className="relative flex items-center justify-between mb-4 lg:mb-5 cursor-pointer gap-8"
                      onClick={() => _setActiveIndex(index)}
                    >
                      <h3 className="text-2xl lg:text-3xl font-normal text-eo-buizel max-lg:text-center">{edge?.title}</h3>
                      <svg
                        className={`w-5 h-5 min-w-5 text-eo-buizel transition-all duration-200  ${activeIndex !== index && '-rotate-90'} `}
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="512"
                        height="512"
                        viewBox="0 0 444.819 444.819"
                        xmlSpace="preserve"
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561   L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416   C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848   c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693   C444.819,130.287,441.295,121.629,434.252,114.203z"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    {activeIndex === index && (
                      <Content className="text-eo-stakataka children-p:leading-6 max-lg:children-p:text-center lg:children-p:text-lg">
                        {edge?.description}
                      </Content>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          fields.items?.map((edge, index) => {
            return (
              <div key={edge?.title} className="bg-white shadow-md lg:shadow-lg mb-10 rounded-2xl overflow-hidden">
                <div className="grid grid-cols-4 p-6 lg:px-20 lg:py-10">
                  <div className="col-span-4 lg:col-span-1 flex justify-center">
                    <LossePlaatjie maxwidth={90} src={edge?.image} className="max-lg:mb-4 max-w-[90px] lg:max-w-none" />
                  </div>
                  <div className="col-span-4 lg:col-span-3">
                    <div className="relative lg:w-fit mb-4 lg:mb-5">
                      <h2 className="text-4xl lg:text-8xl font-normal text-eo-ribombee max-lg:text-center">{edge?.title}</h2>
                      {edge?.subtitle && (
                        <h3 className="max-lg:text-center lg:text-3xl lg:!leading-[0.9] text-eo-buizel bottom-0 lg:absolute lg:left-[85%]">
                          {edge?.subtitle}
                        </h3>
                      )}
                    </div>
                    <Content className="text-eo-stakataka children-p:leading-6 max-lg:children-p:text-center">{edge?.description}</Content>
                  </div>
                </div>
              </div>
            )
          })
        )}

        <div className="lg:w-2/3 mx-auto flex flex-col">
          {fields.links?.map((edge) => {
            return (
              <div key={edge?.link?.title} className="flex flex-col items-center">
                <LosseLink className="btn--blue" src={edge?.link} />
                {edge?.microcopy && <Microcopy text={edge?.microcopy} />}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
