import { LosseBlogBink, LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Posts, Post, WpPageInfo } from '~/graphql/types'

export default function PostsLatest({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="PostsLatest" id={fields.sectionId ?? undefined} className="py-12 lg:py-24">
      <div className="container">
        <Content className="children-h2:text-3xl md:children-h2:text-4xl lg:children-h2:text-6xl children-h3:mb-2 children-h2:mb-8 children-h2:text-eo-buizel children-h3:text-2xl children-h3:text-eo-stakataka">
          {fields.description}
        </Content>
        <LosseBlogBink pageInfo={fields.posts?.pageInfo as WpPageInfo}>
          <Overview fields={fields} />
        </LosseBlogBink>
      </div>
    </section>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const posts = fields.posts?.edges

  return (
    <>
      <div className="grid grid-cols-3 xs:max-lg:grid-cols-2 lg:mt-20 gap-x-4 sm:gap-x-7 md:gap-x-14 gap-y-12">
        {posts
          ?.filter((edge) => {
            const post = edge.node as Post

            const primaryCategory = post.categories?.edges.filter((edge) => {
              // @ts-ignore
              return edge.isPrimary
            })[0].node

            return fields.taxonomy?.databaseId === primaryCategory?.databaseId
          })
          .slice(0, 3)
          .map((edge) => {
            const post = edge.node as Post

            return (
              <LosseLink
                to={post.uri}
                key={post.title}
                className="group col-span-3 xs:col-span-1 flex flex-col shadow-lg rounded-3xl overflow-hidden bg-white"
              >
                <div className="overflow-hidden lg:aspect-square xs:max-h-[325px] md:max-h-[300px]">
                  <LossePlaatjie
                    maxwidth={400}
                    src={post.featuredImage?.node}
                    className="object-cover group-hover:scale-105 transition-transform w-full"
                  />
                </div>

                <div className="px-4 py-5 sm:px-8 sm:py-9 flex flex-col flex-grow">
                  <span className={clsx(!post.recap?.subtitle && 'opacity-0', 'inline-block font-semibold mb-2 lg:mb-4 max-lg:text-sm')}>
                    {post?.recap?.subtitle ?? 'Kennis'}
                  </span>
                  <span className="inline-block mb-2 sm:mb-7 text-eo-buizel font-semibold text-lg sm:text-2xl">{post.title}</span>
                  <div className="flex justify-end mt-auto">
                    <LosseLink to={post.uri} className="btn--blue--transparent max-sm:px-0">
                      {fields.readMoreText}
                    </LosseLink>
                  </div>
                </div>
              </LosseLink>
            )
          })}
      </div>
    </>
  )
}
