import { LosseBlogBink, LosseLink, LossePlaatjie, useLosseBlogBink, type LosseBlogBinkReadableParams } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import Content from '~/components/elements/Content'
import type { Category, Maybe, Page_Flexcontent_Flex_Posts, Post, WpPageInfo } from '~/graphql/types'
import useOnClickOutside from '~/hooks/useOnClickOutside'

export const READABLE_PARAMS: LosseBlogBinkReadableParams = {
  filters: 'onderwerp'
}

export default function PostsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="PostsOverview" id={fields.sectionId ?? undefined} className="py-12 lg:py-24">
      <div className="container">
        <Content className="children-h2:text-6xl children-h3:mb-2 children-h2:mb-8 children-h2:text-eo-buizel children-h3:text-2xl children-h3:text-eo-stakataka">
          {fields.description}
        </Content>
        <LosseBlogBink readableParams={READABLE_PARAMS} pageInfo={fields.posts?.pageInfo as WpPageInfo}>
          <Overview fields={fields} />
        </LosseBlogBink>
      </div>
    </section>
  )
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const parentFilters = fields.filters?.filter((edge) => !edge?.parentDatabaseId)
  const currentDate = new Date()

  return (
    <>
      <div className="grid grid-cols-3 max-lg:mb-8">
        <div className="col-span-3 lg:col-span-1 max-lg:mb-8">
          <Content className="children-headings:text-2xl lg:children-headings:text-6xl">
            <h2>Filter onze kennis</h2>
          </Content>
        </div>
        <div className="col-span-3 lg:col-span-2">
          <div className="flex max-sm:flex-col flex-wrap">
            {parentFilters?.reverse().map((parentFilter) => {
              return <ParentFilter key={parentFilter?.name} parentFilter={parentFilter} fields={fields} />
            })}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 xs:max-lg:grid-cols-2 lg:mt-20 gap-x-4 sm:gap-x-7 md:gap-x-14 gap-y-12">
        {fields.posts?.edges.map((edge) => {
          const post = edge.node as Post

          let date = null

          if (post.recap?.date) {
            date = new Date(post.recap?.date)
          }

          const day = date?.getDate()
          const month = date?.toLocaleString('nl-NL', { month: 'long' })

          const isWorkshop = post.categories?.edges?.some((category) => category?.node?.name === 'Workshops')

          return (
            <LosseLink
              to={post.uri}
              key={post.title}
              className="group col-span-3 xs:col-span-1 flex flex-col shadow-lg rounded-3xl overflow-hidden bg-white"
            >
              <div className="overflow-hidden lg:aspect-square min-h-[250px] xs:max-h-[325px] md:max-h-[300px]">
                {isWorkshop && (
                  <div className="bg-eo-buizel w-full h-full flex flex-col justify-center items-center">
                    <Content className="text-white children-headings:text-3xl children-p:text-xl">
                      {post.recap?.eventFinishedNotification}
                    </Content>
                  </div>
                )}
                {date ? (
                  <>
                    {date < currentDate ? (
                      <div className="bg-eo-claydol w-full h-full flex flex-col justify-center items-center">
                        <Content className="text-white children-headings:text-2xl children-p:text-xl">
                          {post.recap?.eventFinishedNotification}
                        </Content>
                      </div>
                    ) : (
                      <div className="bg-eo-whiscash text-white w-full h-full flex flex-col justify-center items-center">
                        <span className="text-9xl font-bold">{day}</span>
                        <span className="text-5xl font-semibold">{month}</span>
                      </div>
                    )}
                  </>
                ) : (
                  <LossePlaatjie
                    maxwidth={400}
                    src={post.featuredImage?.node}
                    className="object-cover group-hover:scale-105 transition-transform w-full"
                  />
                )}
              </div>

              <div className="px-4 py-5 sm:px-8 sm:py-9 flex flex-col flex-grow">
                <span className={clsx(!post.recap?.subtitle && 'opacity-0', 'inline-block font-semibold mb-2 lg:mb-4 max-lg:text-sm')}>
                  {post?.recap?.subtitle ?? 'Kennis'}
                </span>
                <span className="inline-block mb-2 sm:mb-7 text-eo-buizel font-semibold text-lg sm:text-2xl">{post.title}</span>
                <div className="flex justify-end mt-auto">
                  {date ? (
                    <LosseLink to={post.uri} className="btn--blue--transparent max-sm:px-0">
                      {date < currentDate ? (
                        post.recap?.eventFinishedNotification?.includes('Kijk het event hier terug') ? (
                          <span>Terugkijken</span>
                        ) : (
                          <span>Lees dit artikel</span>
                        )
                      ) : (
                        <span>Meld je aan</span>
                      )}
                    </LosseLink>
                  ) : (
                    <LosseLink to={post.uri} className="btn--blue--transparent max-sm:px-0">
                      {fields?.readMoreText}
                    </LosseLink>
                  )}
                </div>
              </div>
            </LosseLink>
          )
        })}
      </div>
    </>
  )
}

function ParentFilter({ parentFilter, fields }: { parentFilter: Maybe<Category>; fields: Page_Flexcontent_Flex_Posts }) {
  const { toggleFilter, isFilterActive } = useLosseBlogBink()

  const [currentParentFilter, setCurrentParentFilter] = useState(-1)

  function handleParentFilter(databaseId: number) {
    if (databaseId === currentParentFilter) {
      setCurrentParentFilter(-1)
    } else {
      setCurrentParentFilter(databaseId)
    }
  }

  const ref = useRef(null)
  useOnClickOutside(ref, handleClickOutside)

  if (!parentFilter?.name) return null

  function handleClickOutside() {
    setCurrentParentFilter(-1)
  }

  const parentFilterActive = currentParentFilter === parentFilter.databaseId

  return (
    <div className="relative mr-7 mb-6" key={parentFilter.name}>
      <button
        type="button"
        className={clsx(parentFilterActive && 'z-20', 'relative min-w-[12.5rem] w-full rounded-full bg-white hover:bg-eo-ribombee')}
        onClick={() => handleParentFilter(parentFilter.databaseId)}
      >
        <div className="flex w-full items-center justify-between py-2 pl-5 pr-2 opacity-70">
          <span>{parentFilter.name}</span>
          <svg
            width="38"
            height="34"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className="mt-1"
          >
            <path
              fill="#5C5C5C"
              d="m350.56 397.6c-4.4805 0-8.9609-1.6797-11.762-5.0391l-168-168c-5.0391-5.0391-6.1602-11.762-3.9219-18.48 2.8008-6.1602 8.9609-10.641 15.68-10.641h336c6.7188 0 12.879 3.9219 15.68 10.641 2.8008 6.1602 1.1211 13.441-3.9219 18.48l-168 168c-2.8008 3.3594-7.2812 5.0391-11.762 5.0391zm-127.68-168 127.68 127.68 127.68-127.68z"
            />
          </svg>
        </div>
      </button>
      {parentFilterActive && (
        <div ref={ref} className="absolute top-[55%] z-10 w-full overflow-hidden rounded-bl-3xl rounded-br-3xl bg-white pt-6 shadow-2xl">
          {fields.filters
            ?.filter((childFilter) => childFilter?.parentDatabaseId === parentFilter.databaseId)
            .map((childFilter) => {
              if (!childFilter?.name) return null

              const active = isFilterActive(childFilter.databaseId)

              return (
                <button
                  type="button"
                  onClick={() => toggleFilter(childFilter.name)}
                  className={clsx(active && 'underline font-semibold', 'w-full px-2 py-2 hover:underline hover:bg-eo-buizel/30')}
                  key={childFilter.name}
                >
                  {childFilter.name}
                </button>
              )
            })}
        </div>
      )}
    </div>
  )
}
