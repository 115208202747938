import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaTitle({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section
      data-component="CtaTitle"
      id={fields.sectionId ?? undefined}
      className={clsx(fields.containerWidth === 'full' ? 'bg-eo-buizel' : 'bg-transparent', 'relative my-8 lg:my-24')}
    >
      <div className="relative container">
        <div
          className={clsx(
            fields.bgcolor === 'orange' ? 'bg-eo-buizel lg:px-24 px-5' : 'bg-eo-whiscash lg:px-14 px-5',
            'rounded-2xl flex flex-col py-9 lg:py-8 xl:mx-24'
          )}
        >
          {fields.title && (
            <Content className="text-white children-headings:text-2xl mb-4 sm:mb-0 children-headings:mb-2 children-headings:font-montserrat children-headings:font-semibold">
              {fields.title}
            </Content>
          )}
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <Content className="text-white children-headings:text-3xl mb-4 lg:mb-0 lg:w-3/5">{fields.description}</Content>
            <div className="lg:w-2/5 flex justify-end">
              <LosseLink src={fields.link} className={clsx(fields.bgcolor === 'orange' ? 'btn--blue' : 'btn--white--blue')} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
