import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Cases, Case } from '~/graphql/types'

export default function CasesOverview({ fields }: { fields: Page_Flexcontent_Flex_Cases }) {
  return (
    <section data-component="CasesOverview" id={fields.sectionId ?? undefined} className="py-12 lg:py-24">
      <div className="container">
        <div className="grid grid-cols-3 md:max-lg:grid-cols-2 gap-x-5 gap-y-7 lg:gap-x-14 lg:gap-y-12">
          {fields.posts?.edges.map((edge) => {
            const post = edge.node as Case

            return (
              <div key={post.title} className="col-span-3 md:col-span-1 shadow-lg rounded-3xl">
                <LosseLink to={post.uri} className="group flex flex-col h-full">
                  <div className="overflow-hidden aspect-video rounded-t-3xl" style={{ transform: 'translateZ(1px)' }}>
                    <LossePlaatjie
                      maxwidth={400}
                      src={post.featuredImage?.node}
                      className="object-cover h-full group-hover:scale-105 transition-transform"
                    />
                  </div>

                  <div className="px-4 py-5 lg:px-8 lg:py-9 flex flex-col flex-grow">
                    <span className="inline-block mb-4 lg:mb-7 text-eo-buizel font-semibold text-lg lg:text-2xl">{post.title}</span>
                    <div className="flex justify-end mt-auto">
                      <span className="btn--blue--transparent">{fields.readMoreText}</span>
                    </div>
                  </div>
                </LosseLink>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
