import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Navigation } from 'swiper'

import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderDefault({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const sliderId = fields.sliderId
  const prevClass = `${sliderId}-button-prev`
  const nextClass = `${sliderId}-button-next`

  return (
    <section data-component="SliderDefault" id={fields.sectionId ?? undefined}>
      <div className="container py-20">
        <div className="relative flex flex-col lg:flex-row justify-center">
          <div className="w-full lg:w-4/5">
            <Slider modules={[Navigation]} navigation={{ prevEl: `.${prevClass}`, nextEl: `.${nextClass}` }}>
              {fields.slides?.map((edge) => {
                return (
                  <Slide key={edge?.image?.mediaItemUrl} className="!h-auto">
                    <LossePlaatjie maxwidth={1250} src={edge?.image} className="lg:h-[500px] lg:w-full  object-cover" />
                  </Slide>
                )
              })}
            </Slider>
          </div>
          <div className="flex max-lg:justify-end max-lg:mt-2">
            <button className={clsx(prevClass, 'lg:absolute top-[calc(50%-31.5px)] left-0 rotate-180 max-lg:mr-4')}>
              <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="31.5" cy="31.5" r="31.5" fill="#FF8300" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25 17V46L44 31.5013L25 17Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-[0px] absolute">Vorige</span>
            </button>
            <button className={clsx(nextClass, 'lg:absolute top-[calc(50%-31.5px)] right-0')}>
              <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="31.5" cy="31.5" r="31.5" fill="#FF8300" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25 17V46L44 31.5013L25 17Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="text-[0px] absolute">Volgende</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
