import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" id={fields.sectionId ?? undefined} className="pb-12 lg:py-12">
      <div className="container">
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <div className="lg:w-2/5 lg:order-last">
            <LossePlaatjie
              maxwidth={700}
              src={fields.image}
              className="max-w-[90%] md:max-lg:max-w-[60%] mx-auto lg:ml-auto lg:max-w-full"
            />
          </div>
          <div className="lg:w-3/5">
            <Content
              className={clsx(
                fields.titleColor === 'orange' ? 'children-h1:text-eo-buizel' : 'children-h1:text-eo-whiscash',
                'children-h2:text-eo-stakataka children-h2:text-xl lg:children-h2:pr-20 lg:children-h2:text-2xl children-h1:text-4xl lg:children-h1:text-6xl children-headings:mb-3 lg:children-headings:mb-8 lg:children-p:pr-14'
              )}
            >
              {fields.description}
            </Content>
            {fields.links?.map((edge) => {
              return (
                <div key={edge?.link?.title} className="mt-14">
                  <LosseLink className="btn--blue" key={edge?.link?.title} src={edge?.link} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
