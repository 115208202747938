export default function Logo({ className }: { className?: string }) {
  return (
    <svg width="200" height="52" viewBox="0 0 200 52" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? undefined}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.0951 34.9002C94.5623 36.1622 93.825 37.2697 92.8834 38.2216C91.9408 39.1735 90.8455 39.9193 89.5965 40.4571C88.3474 40.9949 87.0058 41.2634 85.5731 41.2634C84.139 41.2634 82.7983 40.9949 81.5493 40.4571C80.2993 39.9193 79.2039 39.1735 78.2628 38.2216C77.3198 37.2697 76.5731 36.1622 76.0203 34.9002C75.467 33.6376 75.1908 32.2819 75.1908 30.8324C75.1908 29.3839 75.467 28.0287 76.0203 26.7661C76.5731 25.5041 77.3198 24.3966 78.2628 23.4447C79.2039 22.4932 80.2993 21.747 81.5493 21.2092C82.7983 20.6714 84.139 20.4015 85.5731 20.4015C87.0058 20.4015 88.3474 20.6714 89.5965 21.2092C90.8455 21.747 91.9408 22.4932 92.8834 23.4447C93.825 24.3966 94.5623 25.5041 95.0951 26.7661C95.6268 28.0287 95.8937 29.3839 95.8937 30.8324C95.8937 32.2819 95.6268 33.6376 95.0951 34.9002ZM96.3852 19.9598C94.9507 18.5183 93.303 17.4061 91.4399 16.6233C89.5754 15.841 87.6203 15.4493 85.5731 15.4493C83.4838 15.4493 81.5184 15.841 79.6754 16.6233C77.8329 17.4061 76.1941 18.5183 74.7609 19.9598C73.3268 21.4008 72.2212 23.0493 71.4437 24.9026C70.6652 26.7558 70.2764 28.7328 70.2764 30.8324C70.2764 32.8928 70.6652 34.8594 71.4437 36.7328C72.2212 38.6076 73.3268 40.2646 74.7609 41.7056C76.1941 43.1475 77.8329 44.2602 79.6754 45.0425C81.5184 45.8253 83.4838 46.216 85.5731 46.216C87.6203 46.216 89.5754 45.8253 91.4399 45.0425C93.303 44.2602 94.9507 43.1475 96.3852 41.7056C97.818 40.2646 98.9133 38.6076 99.6717 36.7328C100.429 34.8594 100.809 32.8928 100.809 30.8324C100.809 28.7328 100.429 26.7558 99.6717 24.9026C98.9133 23.0493 97.818 21.4008 96.3852 19.9598Z"
        fill="white"
      />
      <mask id="mask0_356_764" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="102" y="0" width="16" height="46">
        <path fillRule="evenodd" clipRule="evenodd" d="M102.773 0.468475H117.886V45.8446H102.773V0.468475Z" fill="white" />
      </mask>
      <g mask="url(#mask0_356_764)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.688 12.8494C107.728 11.8183 107.965 10.8481 108.395 9.93979C108.825 9.03283 109.387 8.24815 110.084 7.58761C110.78 6.92754 111.589 6.40144 112.51 6.00887C113.432 5.61723 114.404 5.42094 115.428 5.42094C116.125 5.42094 116.708 5.1839 117.18 4.70887C117.65 4.23478 117.886 3.64685 117.886 2.94462C117.886 2.24332 117.65 1.65492 117.18 1.18036C116.708 0.706274 116.125 0.468292 115.428 0.468292C113.667 0.468292 112.019 0.799031 110.483 1.4591C108.947 2.11964 107.605 3.028 106.459 4.18278C105.312 5.33849 104.411 6.69002 103.756 8.23737C103.101 9.78519 102.773 11.4469 102.773 13.2205V13.5306V43.3683C102.773 44.0705 103.008 44.6589 103.48 45.133C103.95 45.6076 104.534 45.8446 105.231 45.8446C105.926 45.8446 106.51 45.6076 106.982 45.133C107.452 44.6589 107.688 44.0705 107.688 43.3683V21.2683H115.428C116.125 21.2683 116.708 21.0312 117.18 20.5567C117.65 20.0826 117.886 19.4947 117.886 18.7924C117.886 18.0907 117.65 17.5027 117.18 17.0277C116.708 16.5541 116.125 16.3161 115.428 16.3161H107.688V13.035V12.8494Z"
          fill="white"
        />
      </g>
      <mask id="mask1_356_764" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="119" y="0" width="16" height="46">
        <path fillRule="evenodd" clipRule="evenodd" d="M119.852 0.468475H134.964V45.8446H119.852V0.468475Z" fill="white" />
      </mask>
      <g mask="url(#mask1_356_764)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.766 12.8494C124.807 11.8183 125.043 10.8481 125.473 9.93979C125.903 9.03283 126.466 8.24815 127.162 7.58761C127.858 6.92754 128.667 6.40144 129.588 6.00887C130.51 5.61723 131.482 5.42094 132.506 5.42094C133.203 5.42094 133.786 5.1839 134.258 4.70887C134.728 4.23478 134.964 3.64685 134.964 2.94462C134.964 2.24332 134.728 1.65492 134.258 1.18036C133.786 0.706274 133.203 0.468292 132.506 0.468292C130.745 0.468292 129.097 0.799031 127.561 1.4591C126.025 2.11964 124.684 3.028 123.538 4.18278C122.39 5.33849 121.489 6.69002 120.835 8.23737C120.179 9.78519 119.852 11.4469 119.852 13.2205V13.5306V43.3683C119.852 44.0705 120.087 44.6589 120.558 45.133C121.029 45.6076 121.612 45.8446 122.309 45.8446C123.005 45.8446 123.588 45.6076 124.06 45.133C124.53 44.6589 124.766 44.0705 124.766 43.3683V21.2683H132.506C133.203 21.2683 133.786 21.0312 134.258 20.5567C134.728 20.0826 134.964 19.4947 134.964 18.7924C134.964 18.0907 134.728 17.5027 134.258 17.0277C133.786 16.5541 133.203 16.3161 132.506 16.3161H124.766V13.035V12.8494Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.572 7.09216C138.835 7.09216 138.209 7.35357 137.698 7.87544C137.186 8.39825 136.93 9.03443 136.93 9.78539C136.93 10.5368 137.186 11.173 137.698 11.6953C138.209 12.2177 138.835 12.4781 139.572 12.4781C140.309 12.4781 140.933 12.2177 141.445 11.6953C141.957 11.173 142.213 10.5368 142.213 9.78539C142.213 9.03443 141.957 8.39825 141.445 7.87544C140.933 7.35357 140.309 7.09216 139.572 7.09216Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.51 15.5731C138.813 15.5731 138.23 15.811 137.76 16.2851C137.288 16.7602 137.053 17.3481 137.053 18.0494V42.8112C137.053 43.5135 137.288 44.1014 137.76 44.5755C138.23 45.0505 138.813 45.2875 139.51 45.2875C140.206 45.2875 140.789 45.0505 141.261 44.5755C141.731 44.1014 141.967 43.5135 141.967 42.8112V18.0494C141.967 17.3481 141.731 16.7602 141.261 16.2851C140.789 15.811 140.206 15.5731 139.51 15.5731Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.721 37.2706C168.045 37.2922 167.482 37.5494 167.032 38.0445C166.048 39.0766 164.922 39.8706 163.653 40.4281C162.383 40.9851 161.093 41.2849 159.783 41.3257C158.471 41.3669 157.171 41.1505 155.882 40.6754C154.591 40.2014 153.434 39.4682 152.411 38.4779C150.322 36.4972 149.236 34.0827 149.155 31.2349C149.032 28.3468 149.953 25.8494 151.919 23.7445C152.861 22.7134 153.956 21.9287 155.206 21.3919C156.455 20.8564 157.775 20.5468 159.169 20.4638C161.994 20.3814 164.451 21.3305 166.54 23.3112C167.032 23.7656 167.625 23.9825 168.322 23.961C169.018 23.9408 169.591 23.6827 170.042 23.1875C170.492 22.6923 170.707 22.0941 170.687 21.3919C170.666 20.691 170.41 20.1134 169.919 19.659C168.444 18.2564 166.764 17.1935 164.881 16.4706C162.997 15.7492 161.031 15.4287 158.984 15.5112C156.935 15.5941 155 16.0481 153.178 16.873C151.356 17.6994 149.748 18.8542 148.356 20.3397C146.922 21.8674 145.857 23.5693 145.162 25.4469C144.465 27.3255 144.158 29.2959 144.24 31.359C144.322 33.4638 144.772 35.4244 145.592 37.2402C146.411 39.0564 147.557 40.6661 149.032 42.0682V42.1305C150.465 43.4516 152.073 44.4733 153.855 45.1948C155.636 45.9171 157.51 46.2779 159.476 46.2779H159.967C162.014 46.1959 163.949 45.7419 165.772 44.916C167.594 44.091 169.202 42.9147 170.595 41.3875C171.045 40.8923 171.26 40.3039 171.24 39.6233C171.219 38.9421 170.963 38.3753 170.472 37.9208C169.98 37.4669 169.397 37.2505 168.721 37.2706Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.748 36.0018C179.174 35.0531 178.764 34.0422 178.519 32.9684C178.273 31.8956 178.201 30.8228 178.304 29.7491C178.406 28.6768 178.683 27.6241 179.133 26.5921C179.583 25.561 180.218 24.6325 181.038 23.8066C182.02 22.7755 183.136 22.0016 184.386 21.4849C185.635 20.9695 186.914 20.7109 188.226 20.7109C189.904 20.7109 191.521 21.1447 193.078 22.0109L179.748 36.0018ZM198.915 37.5655C198.423 37.113 197.829 36.8975 197.133 36.9176C196.436 36.9387 195.843 37.1963 195.352 37.6896C194.532 38.5137 193.632 39.1733 192.648 39.6666C191.666 40.1608 190.641 40.4906 189.577 40.6551C188.512 40.8195 187.447 40.7993 186.382 40.5932C185.317 40.388 184.314 40.0165 183.372 39.4811L198.607 23.4815C199.057 22.9455 199.283 22.3689 199.283 21.7509C199.283 21.134 199.057 20.5573 198.607 20.0214L198.546 19.9595C197.112 18.5602 195.474 17.4996 193.632 16.7781C191.789 16.0576 189.884 15.7185 187.918 15.7588C185.911 15.8421 184.006 16.2745 182.205 17.0569C180.402 17.8392 178.826 18.9509 177.475 20.3919C176.082 21.8343 175.027 23.4716 174.311 25.3038C173.594 27.1369 173.256 29.0619 173.297 31.0791C173.338 33.0982 173.758 35.0025 174.557 36.7939C175.355 38.5854 176.47 40.181 177.905 41.5817C179.297 42.9416 180.874 43.9816 182.635 44.7022C184.395 45.4227 186.26 45.7824 188.226 45.7824C188.266 45.7824 188.307 45.7731 188.348 45.7515C188.389 45.7314 188.45 45.7206 188.533 45.7206C190.539 45.6803 192.433 45.2681 194.215 44.4857C195.996 43.7029 197.583 42.5908 198.976 41.1493C199.426 40.6551 199.652 40.0582 199.652 39.3583C199.652 38.6575 199.406 38.0611 198.915 37.5655Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.8789 27.9541H50.5925C49.8958 27.9541 49.3121 28.1911 48.8416 28.6661C48.3705 29.1398 48.135 29.7282 48.135 30.4304C48.135 31.1317 48.3705 31.7196 48.8416 32.1946C49.3121 32.6683 49.8958 32.9063 50.5925 32.9063H62.8789C63.5747 32.9063 64.1584 32.6683 64.6299 32.1946C65.1 31.7196 65.3364 31.1317 65.3364 30.4304C65.3364 29.7282 65.1 29.1398 64.6299 28.6661C64.1584 28.1911 63.5747 27.9541 62.8789 27.9541Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.91179 16.617C5.11506 16.617 5.31553 16.5229 5.44497 16.3453C8.60338 12.004 13.2375 9.02739 18.4945 7.96397C23.1595 7.02047 27.8767 7.63088 32.1366 9.72822C32.4637 9.88937 32.8585 9.75398 33.0188 9.42606C33.1791 9.09813 33.0445 8.7018 32.7174 8.54112C28.194 6.31402 23.1861 5.66613 18.2342 6.66771C12.6529 7.79625 7.73282 10.9565 4.37955 15.5658C4.16506 15.8609 4.22955 16.2746 4.52394 16.4901C4.64076 16.5758 4.77674 16.617 4.91179 16.617Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9594 24.3672C43.7805 18.6701 40.5225 13.6973 35.773 10.3539C35.4744 10.144 35.0636 10.2166 34.8548 10.5155C34.6459 10.8144 34.7174 11.2271 35.0155 11.4365C39.5234 14.6099 42.6062 19.3419 43.6954 24.7602C43.7034 24.7996 43.7146 24.8375 43.7286 24.874C44.023 26.3797 44.1585 27.9135 44.1295 29.435C44.1225 29.8 44.4118 30.1017 44.7758 30.1087C44.78 30.1087 44.7847 30.1087 44.7893 30.1087C45.1468 30.1087 45.4407 29.8211 45.4477 29.4603C45.4795 27.8034 45.3286 26.1337 44.9996 24.4965C44.9903 24.4515 44.9767 24.4079 44.9594 24.3672Z"
        fill="white"
      />
      <mask id="mask2_356_764" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="18" width="4" height="17">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 18.2694H3.74547V34.9782H0V18.2694Z" fill="white" />
      </mask>
      <g mask="url(#mask2_356_764)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.96712 34.1579C1.89328 33.8571 1.82413 33.5493 1.76291 33.242C1.7545 33.2027 1.74328 33.1657 1.7288 33.1296C0.795154 28.3573 1.46618 23.5546 3.67272 19.2325C3.83908 18.9074 3.71057 18.5087 3.38674 18.3424C3.06291 18.1756 2.66525 18.3044 2.49936 18.6296C0.137677 23.2557 -0.568397 28.3995 0.458238 33.5058C0.467117 33.5493 0.480201 33.5906 0.497023 33.6299C0.555434 33.9138 0.618518 34.1972 0.687677 34.4755C0.761509 34.7767 1.03067 34.9782 1.32693 34.9782C1.37927 34.9782 1.43207 34.9721 1.48581 34.9585C1.83908 34.8709 2.0545 34.5125 1.96712 34.1579Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5986 28.353C22.2416 28.4252 22.0108 28.7737 22.0827 29.1321C22.4472 30.9451 22.6028 32.7646 22.5603 34.5621L23.8738 33.1904C23.829 31.7551 23.665 30.3112 23.3752 28.8702C23.3037 28.5123 22.9551 28.28 22.5986 28.353Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8853 42.8333C19.8727 45.392 18.4134 47.7953 16.5339 49.9413C16.2937 50.2154 16.3204 50.6333 16.5937 50.8745C16.7194 50.9851 16.8746 51.0389 17.0292 51.0389C17.2124 51.0389 17.3942 50.9631 17.525 50.8141C19.4405 48.6263 20.9405 46.1847 22.0031 43.5865C21.6199 43.3533 21.247 43.1026 20.8853 42.8333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5835 41.7432C19.5092 41.6739 19.4335 41.6069 19.3597 41.5362C19.2882 41.4664 19.2209 41.3942 19.1508 41.3235C18.8251 41.2247 18.4733 41.3896 18.3471 41.7133C17.2933 44.4224 15.7321 46.8528 13.7064 48.9361C13.4522 49.198 13.4578 49.6163 13.7181 49.8716C13.8466 49.9967 14.0125 50.059 14.1784 50.059C14.3499 50.059 14.5214 49.9925 14.6504 49.8595C16.7999 47.6483 18.4574 45.0694 19.5756 42.1939C19.634 42.0435 19.6312 41.8852 19.5835 41.7432Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9801 39.997C26.1361 39.285 26.2642 38.5673 26.3651 37.8444L24.8352 39.4428C25.2072 39.6536 25.5889 39.8378 25.9801 39.997Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0966 44.6447C23.8135 44.5294 23.5368 44.4029 23.263 44.2713C22.3396 46.5017 21.113 48.621 19.5976 50.5666C19.3733 50.8542 19.4237 51.2697 19.7107 51.4951C19.8312 51.5892 19.9747 51.6352 20.1163 51.6352C20.3121 51.6352 20.506 51.548 20.6364 51.3808C22.2233 49.3434 23.5069 47.1238 24.4751 44.788C24.3485 44.7407 24.2219 44.6957 24.0966 44.6447Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1886 28.9057L26.2026 28.901L26.0863 28.321C25.7124 26.4607 23.8998 25.2526 22.0452 25.6278C21.5881 25.7206 21.1713 25.9014 20.8064 26.1483C20.7307 26.2977 20.6578 26.4481 20.5905 26.6022C20.1386 27.631 19.8615 28.6789 19.7596 29.7475C19.6568 30.8175 19.7293 31.8856 19.9751 32.9547C20.1994 33.9286 20.5601 34.8496 21.0564 35.7205C21.2213 33.6246 21.0947 31.5146 20.6723 29.4173L20.6152 29.132C20.5508 28.0929 21.2606 27.1358 22.3059 26.9245C23.3839 26.7072 24.4377 27.3565 24.7466 28.3932L24.7853 28.5853C24.9984 29.6454 25.1447 30.7102 25.2297 31.7751L26.4302 30.5219C26.3648 29.9827 26.2853 29.4435 26.1886 28.9057Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6054 37.9541C15.8003 41.6011 13.9143 44.9839 11.1227 47.6046C10.8563 47.8538 10.8428 48.2721 11.0914 48.5391C11.2213 48.6783 11.397 48.7486 11.5727 48.7486C11.7344 48.7486 11.8961 48.6895 12.0232 48.5701C14.6933 46.0642 16.59 42.9068 17.5783 39.4776C17.2236 38.9885 16.8989 38.4811 16.6054 37.9541Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4876 28.0387C27.5652 28.4247 27.6334 28.8122 27.6951 29.2001L28.8339 28.0116L28.7862 27.7754C28.46 26.1503 27.5222 24.7505 26.146 23.8333C25.3582 23.3081 24.4801 22.9783 23.5703 22.8528C23.197 23.1474 22.8385 23.4707 22.497 23.8267C22.3918 23.9326 22.2895 24.0404 22.1899 24.1495C23.3241 24.0221 24.4549 24.2943 25.4166 24.9351C26.4273 25.6088 27.1381 26.6141 27.4371 27.7858L27.4876 28.0387Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.655 40.6707C28.8919 39.6078 29.0765 38.5336 29.1994 37.4509C29.2405 37.0879 28.9807 36.7604 28.619 36.7187C28.2568 36.678 27.9307 36.9384 27.8891 37.301C27.769 38.3569 27.5882 39.4049 27.355 40.4416C27.5181 40.4819 27.683 40.5189 27.8494 40.5508C28.1181 40.6028 28.3868 40.6426 28.655 40.6707Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8489 45.2266C25.0573 47.1585 24.0644 49.0104 22.8835 50.7414C22.6779 51.0426 22.755 51.4544 23.055 51.6605C23.169 51.7383 23.2989 51.7757 23.427 51.7757C23.6377 51.7757 23.8438 51.6755 23.9714 51.4881C25.2499 49.6152 26.3158 47.6054 27.1569 45.5086C26.7153 45.4346 26.2793 45.3404 25.8489 45.2266Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7462 31.0728C14.7046 29.0621 15.043 27.1447 15.7612 25.3186C16.3369 23.8523 17.1355 22.5134 18.149 21.2982C14.9845 23.1932 13.1911 26.9494 13.9598 30.7744L14.0523 31.1824C15.0084 36.6804 13.0126 42.3212 8.78735 45.9926C8.51211 46.2315 8.48221 46.6494 8.72006 46.9253C8.8509 47.0766 9.03407 47.1539 9.21912 47.1539C9.37192 47.1539 9.52566 47.1005 9.65043 46.9923C13.0486 44.0405 15.113 39.9105 15.5271 35.542C15.0397 34.1197 14.7771 32.6309 14.7462 31.0728Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0377 25.711C30.2858 23.7968 28.8961 22.1858 27.0732 21.1664C27.0423 21.1491 27.011 21.1374 26.9788 21.1256C26.7208 21.1987 26.4634 21.2821 26.2073 21.3772C26.1975 21.3922 26.1853 21.4058 26.1765 21.4217C25.9993 21.7407 26.1134 22.1436 26.4316 22.3216C28.1671 23.2923 29.4433 24.8926 30.0143 26.7796L31.0377 25.711Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5031 40.5314C32.0671 37.6906 32.2638 34.7795 32.0858 31.8675L30.8223 33.1877C30.8802 35.7193 30.6447 38.242 30.1208 40.7071C30.4297 40.6902 30.7391 40.6602 31.0484 40.6129C31.2012 40.5895 31.3526 40.5614 31.5031 40.5314Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8761 39.16C12.0079 38.8195 11.8397 38.4363 11.4999 38.3041C11.1607 38.172 10.7784 38.3407 10.6467 38.6813C9.8247 40.8044 8.46255 42.689 6.70741 44.1314C6.4261 44.3629 6.38451 44.7793 6.61535 45.0623C6.74573 45.2216 6.93498 45.304 7.12563 45.304C7.27236 45.304 7.42049 45.2553 7.54339 45.1546C9.4733 43.5683 10.9714 41.4954 11.8761 39.16Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1279 20.8543C30.655 20.7831 30.1779 20.7433 29.6952 20.7433C29.4811 20.7433 29.2685 20.7527 29.0559 20.7663C30.3447 21.7618 31.4045 23.0613 32.1162 24.5843L33.0868 23.5715C32.5615 22.5643 31.8989 21.6526 31.1279 20.8543Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.497 39.4318C35.011 36.0509 35.0465 32.6334 34.6049 29.2351L33.4194 30.4737C33.7236 33.5371 33.6288 36.6107 33.1292 39.6506C33.1063 39.7892 33.1288 39.9237 33.1848 40.0413C33.5017 39.9195 33.8152 39.7827 34.1241 39.6281C34.2498 39.5658 34.3741 39.5002 34.497 39.4318Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6728 18.7986C20.706 18.7916 20.7401 18.7874 20.7738 18.7808C21.5499 18.199 22.3831 17.7001 23.2733 17.2841C22.3345 17.2395 21.3747 17.307 20.4116 17.5019C14.0906 18.7803 9.98497 24.9796 11.2593 31.3208L11.3429 31.6965C11.5499 32.9014 11.5892 34.1213 11.4602 35.3281C11.4214 35.6911 11.684 36.0172 12.0457 36.0561C12.4083 36.0959 12.7331 35.8317 12.7714 35.4686C12.928 34.0013 12.8583 32.5168 12.5649 31.0566L12.4873 30.708C11.5738 25.2059 15.1808 19.9094 20.6728 18.7986Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7696 44.7915C34.499 45.7008 34.1953 46.6021 33.8551 47.4931C33.7252 47.8347 33.8957 48.2169 34.2355 48.3472C34.3126 48.3767 34.3925 48.3912 34.4705 48.3912C34.7364 48.3912 34.9864 48.2291 35.0869 47.9654C35.5672 46.7061 35.9798 45.4272 36.3266 44.1338C36.1182 44.2364 35.9074 44.3352 35.6934 44.4289C35.3892 44.5619 35.0803 44.68 34.7696 44.7915Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8684 27.9154C36.4221 31.3802 36.4946 34.8825 36.095 38.3304C36.3469 38.1196 36.5932 37.8966 36.8315 37.6586C37.0464 37.4431 37.2824 37.2759 37.5371 37.1508C37.8189 33.6907 37.6427 30.1889 37.0021 26.7311L35.8684 27.9154Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5551 22.0383C34.2224 21.8537 33.8864 21.6935 33.5481 21.5488C33.756 21.8519 33.9537 22.1606 34.1341 22.4777L34.5551 22.0383Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2412 17.0297C18.158 16.6086 19.129 16.2919 20.1281 16.09C22.1449 15.6824 24.1884 15.7597 26.1178 16.2778C26.9889 16.0609 27.8819 15.9162 28.7968 15.8468C26.037 14.5609 22.923 14.1753 19.8674 14.7932C18.7692 15.0158 17.7005 15.3634 16.6912 15.8276C16.3604 15.9794 16.2155 16.372 16.3669 16.7041C16.5188 17.0363 16.9099 17.182 17.2412 17.0297Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86218 31.603L9.78507 31.2479C8.97199 26.5454 10.7407 21.7919 14.4528 18.7679C14.7355 18.5374 14.7785 18.121 14.5486 17.8376C14.3192 17.5537 13.9038 17.5106 13.6215 17.741C9.4332 21.1529 7.49349 26.5656 8.55937 31.8668L8.63087 32.197C9.25704 35.9204 7.84021 39.6705 4.88507 42.0518C4.60096 42.2804 4.5561 42.6968 4.78414 42.9817C4.91405 43.1442 5.10517 43.2285 5.29816 43.2285C5.44349 43.2285 5.58881 43.1808 5.71078 43.0828C9.13975 40.3198 10.7299 35.9209 9.86218 31.603Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15975 32.15L7.08311 31.792C6.95601 31.0776 6.87751 30.3529 6.84807 29.6319C6.83311 29.267 6.52704 28.9803 6.16208 28.9981C5.79853 29.0136 5.51536 29.3213 5.53078 29.6863C5.56769 30.5975 5.67797 31.5152 5.85881 32.4132L5.91769 32.6882C6.34947 35.3332 5.36676 38.0246 3.31442 39.7687C3.03732 40.0048 3.00274 40.4217 3.23779 40.7004C3.36863 40.855 3.55414 40.9342 3.74152 40.9342C3.89199 40.9342 4.04339 40.8831 4.16722 40.7777C6.65741 38.6612 7.80414 35.3552 7.15975 32.15Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6667 27.9674C38.7153 28.2958 38.9966 28.5319 39.3185 28.5319C39.3503 28.5319 39.3826 28.5296 39.4157 28.5244C39.776 28.471 40.0246 28.1342 39.9713 27.773C39.8643 27.0497 39.7363 26.3156 39.5905 25.5913L39.4891 25.1018L39.4816 25.1008C39.4227 24.8455 39.3578 24.5935 39.2877 24.3433L38.2083 25.4709C38.2368 25.5984 38.269 25.7239 38.2947 25.8523L38.3573 26.1525C38.4741 26.7569 38.5779 27.3659 38.6667 27.9674Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3229 12.085C15.8575 12.7858 12.7388 14.5055 10.3033 17.0582C7.92337 19.5519 6.35562 22.6616 5.7687 26.0495C5.70608 26.4093 5.94674 26.7518 6.30562 26.8141C6.66449 26.8773 7.00562 26.6361 7.0673 26.2758C7.60982 23.1474 9.05795 20.2761 11.2556 17.9727C13.5042 15.6163 16.3837 14.0286 19.5837 13.3812C24.0154 12.485 28.3939 13.5456 31.8332 15.9625C32.8351 16.1077 33.8196 16.3569 34.7883 16.7092C30.85 12.8312 25.1276 10.9105 19.3229 12.085Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7413 44.4154C37.6478 44.7682 37.8567 45.1308 38.2086 45.225C38.2656 45.24 38.3226 45.247 38.3787 45.247C38.6703 45.247 38.9371 45.0516 39.0156 44.756C39.2707 43.7952 39.4894 42.8273 39.6763 41.8548C39.1614 42.3167 38.6235 42.7393 38.0614 43.1201C37.9614 43.5535 37.8558 43.9854 37.7413 44.4154Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6441 30.6942C39.2806 30.7172 39.0049 31.0315 39.0278 31.396C39.1437 33.2371 39.1325 35.0768 38.9998 36.9057C39.4815 36.9567 39.9091 37.1333 40.2811 37.4369C40.4502 35.4047 40.4722 33.3598 40.3437 31.3126C40.3203 30.9482 40.0068 30.6741 39.6441 30.6942Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.45733 32.6964L4.38536 32.3535C3.5134 27.5686 4.50639 22.7171 7.19751 18.6546C9.95312 14.4946 14.1583 11.6599 19.0391 10.6728C21.0891 10.2582 23.1685 10.1894 25.2209 10.4672C25.5812 10.5168 25.9134 10.2624 25.9625 9.90031C26.0111 9.53818 25.7578 9.2051 25.3975 9.15638C23.1994 8.85843 20.9723 8.93245 18.7784 9.37609C13.5527 10.4334 9.04938 13.4686 6.09938 17.9224C3.14891 22.377 2.10452 27.7171 3.15873 32.9597L3.20125 33.1644C3.43209 34.6359 2.97462 36.1495 1.95733 37.2495C1.70966 37.5179 1.72555 37.9363 1.99284 38.1846C2.11947 38.3026 2.28069 38.3607 2.44097 38.3607C2.61761 38.3607 2.79424 38.2895 2.92462 38.149C4.27648 36.6859 4.84985 34.6476 4.45733 32.6964Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1745 24.4818H42.166C40.5838 17.6313 35.5081 12.0856 28.8006 9.92832C28.4544 9.81682 28.0829 10.0084 27.9716 10.356C27.8604 10.7041 28.0511 11.0761 28.3983 11.188C32.3889 12.4712 35.76 15.0398 38.0642 18.3889C38.7567 18.8629 39.4132 19.3956 40.0301 19.9943L40.0917 20.0566C40.5427 20.5902 40.7679 21.1641 40.7679 21.7787C40.7679 22.2336 40.6445 22.6655 40.3988 23.0749C40.6408 23.8006 40.8417 24.545 40.995 25.3063L41.0759 25.6904C41.9427 30.2055 42.0969 34.7881 41.5347 39.3191C41.4899 39.6817 41.7459 40.012 42.1072 40.0569C42.1352 40.0602 42.1628 40.0621 42.1894 40.0621C42.5174 40.0621 42.802 39.8171 42.8431 39.4826C43.4389 34.6813 43.2539 29.8237 42.2931 25.0444L42.1745 24.4818Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7192 45.7182C29.7108 45.7186 29.7029 45.721 29.6949 45.721C29.6571 45.721 29.6192 45.72 29.5814 45.72C29.5716 45.72 29.5618 45.72 29.552 45.72C29.244 45.72 28.9393 45.7097 28.6365 45.6915C28.0127 47.3021 27.258 48.87 26.3753 50.3794C26.1912 50.6947 26.2964 51.0995 26.6108 51.2845C26.715 51.3463 26.83 51.3754 26.9435 51.3754C27.1697 51.3754 27.3903 51.2587 27.5127 51.0484C28.515 49.3347 29.3571 47.5485 30.0379 45.7116C29.9319 45.7149 29.8258 45.7172 29.7192 45.7182Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0428 49.3637C29.8834 49.6921 30.0194 50.0879 30.3465 50.2481C30.44 50.2936 30.5386 50.3151 30.6353 50.3151C30.8792 50.3151 31.1138 50.1783 31.2283 49.9432C31.9587 48.4394 32.5848 46.8817 33.1044 45.2927C32.6175 45.4117 32.1208 45.5072 31.6166 45.577C31.1652 46.8677 30.6395 48.1344 30.0428 49.3637Z"
        fill="white"
      />
    </svg>
  )
}
