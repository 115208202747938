import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex components
import BannerShell from './Banner/BannerShell'
import ContentShell from './Content/ContentShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import SliderShell from './Slider/SliderShell'
import CasesShell from './Cases/CasesShell'
import CtaShell from './Cta/CtaShell'
import GoogleMaps from './GoogleMaps'
import TeammembersShell from './Teammembers/TeammembersShell'
import SolutionsShell from './Solutions/SolutionsShell'
import ServicesShell from './Services/ServicesShell'
import Searchresults from './Searchresults'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Cta`]: CtaShell,
    [`${prefix}_Flex_Googlemaps`]: GoogleMaps,
    [`${prefix}_Flex_Searchresults`]: Searchresults,
    [`${prefix}_Flex_Content`]: ContentShell,
    [`${prefix}_Flex_Slider`]: SliderShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Cases`]: CasesShell,
    [`${prefix}_Flex_Teammembers`]: TeammembersShell,
    [`${prefix}_Flex_Services`]: ServicesShell,
    [`${prefix}_Flex_Solutions`]: SolutionsShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  return <LosseFlex flex={flex as any} flexers={flexers as any} />
}
