import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentColumns({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentColumns" id={fields.sectionId ?? undefined} className="my-20">
      <div className="container">
        <div className="grid grid-cols-2 gap-8">
          {fields.items?.map((edge) => {
            return (
              <div
                key={edge?.description}
                className={clsx(
                  fields.bgcolorColumns === 'gray' && 'bg-eo-ribombee',
                  'col-span-2 lg:col-span-1 p-6 lg:p-16 rounded-lg lg:rounded-3xl'
                )}
              >
                <Content className="children-headings:font-semibold children-headings:text-2xl children-headings:mb-4 lg:children-headings:mb-8 children-headings:!font-montserrat pr-4 lg:pr-12">
                  {edge?.description}
                </Content>
              </div>
            )
          })}
        </div>
        {fields.links && (
          <div className="flex justify-center mt-14 lg:mt-28">
            {fields.links.map((edge) => {
              return <LosseLink key={edge?.link?.url} src={edge?.link} className="btn--blue" />
            })}
          </div>
        )}
      </div>
    </section>
  )
}
