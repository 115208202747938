import Content from '~/components/elements/Content'
import type { Maybe, MediaItem, Page_Flexcontent_Flex_Form } from '~/graphql/types'
import { LosseLink, LossePlaatjie, useSearchParams } from '@ubo/losse-sjedel'
import Fingerprint from '~/components/elements/Fingerprint'
import { useEffect, useState } from 'react'
import clsx from 'clsx'

export default function FormEmployeeExperienceThanks({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [searchParams] = useSearchParams()
  const [descriptionIndex, setDescriptionIndex] = useState(-1)

  useEffect(() => {
    if (searchParams.has('Punten')) {
      const points = parseInt(searchParams.get('Punten') || '0')

      fields.pointtexts?.forEach((pointtext, index) => {
        if (points >= (pointtext?.from || 0) && points <= (pointtext?.to || 0)) {
          setDescriptionIndex(index)
        }
      })
    }
  }, [fields.pointtexts, searchParams])

  return (
    <section
      data-component="FormEmployeeExperienceThanks"
      id={fields.sectionId ?? undefined}
      className="pt-24 pb-12 lg:py-24 bg-eo-ribombee min-h-screen !mb-0"
    >
      <Fingerprint className="absolute -right-40 top-40 opacity-20" />
      <div className="container">
        <div className="flex justify-center">
          <div className="lg:w-[82%]">
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
              <div className="col-span-1">
                <Content className="children-headings:text-7xl children-headings:text-eo-buizel">{fields.title}</Content>
              </div>
              <div className="col-span-1">
                <Content className="children-headings:text-2xl children-headings:text-eo-buizel">{fields.description}</Content>
              </div>
            </div>

            <div className="w-3/4 ml-36">
              <div className="my-7">
                <ChatBubble
                  image={fields.image}
                  content={`<p class="mb-0"><strong>Beste ${searchParams.get('Naam') || ''}</strong></p><br />${
                    fields.pointtexts![descriptionIndex]?.description || ''
                  }`}
                  links={fields.pointtexts![descriptionIndex]?.links || []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ChatBubble({ image, content, links }: { image: Maybe<MediaItem> | undefined; content: Maybe<string | undefined>; links: any }) {
  return (
    <div className="relative flex items-end">
      <div className="relative bg-white shadow-lg rounded-t-3xl rounded-br-3xl px-12 py-7">
        <svg
          width="76"
          height="20"
          viewBox="0 0 76 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 -left-4"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M0 20H75.9558L18.6395 0L0 20Z" fill="white" />
        </svg>
        <Content>{content}</Content>
        {links?.map((edge: any) => {
          return (
            <div
              key={edge?.link?.title}
              className={clsx('lg:items-start flex flex-col items-center justify-center lg:justify-start mt-7 lg:mt-14')}
            >
              <div>
                <LosseLink className="btn--blue" key={edge?.link?.title} src={edge?.link} />
              </div>
            </div>
          )
        })}
      </div>
      <LossePlaatjie maxwidth={80} src={image} className="min-w-[5rem] max-w-[5rem] -mb-8 absolute -left-24 bottom-0" />
    </div>
  )
}
