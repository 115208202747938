import Content from '~/components/elements/Content'
import type { GfEntry, Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { useState } from 'react'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'

type Props = Page_Flexcontent_Flex_Form & {
  subdata?: {
    FormEntries: GfEntry[]
  }
}

export default function FormDownload({ fields }: { fields: Props }) {
  const [formStatus, setFormStatus] = useState('')

  console.log('FormDownload', fields)

  return (
    <section data-component="FormDownload" id={fields.sectionId ?? undefined} className="pt-24 pb-12 lg:py-24">
      <div className="container">
        <div className="relative bg-white shadow-xl rounded-3xl p-7 lg:p-16 lg:mx-24">
          {fields.image && (
            <LossePlaatjie src={fields.image} className="absolute -right-4 -top-24 md:-top-36 lg:-top-24 w-72 h-auto max-md:w-44" />
          )}
          {formStatus !== 'done' ? (
            <Content
              className={clsx(
                fields.image && 'md:children-headings:pr-60 max-md:pt-8',
                'children-headings:text-2xl  md:children-headings:text-3xl lg:children-headings:text-6xl children-headings:text-eo-whiscash children-strong:!font-semibold children-headings:mb-7 children-p:text-eo-beautifly/70'
              )}
            >
              {fields?.description}
            </Content>
          ) : (
            <Content
              className={clsx(
                fields.image && 'children-headings:pr-60',
                'children-headings:text-3xl lg:children-headings:text-6xl children-headings:text-eo-whiscash children-strong:!font-semibold children-headings:mb-7 children-p:text-eo-beautifly/70'
              )}
            >
              <h2>Gelukt!</h2>
            </Content>
          )}

          <div className="mt-12">
            {fields?.form?.entryLimits?.hasLimit &&
            fields?.subdata?.FormEntries?.length &&
            fields?.subdata?.FormEntries?.length > fields?.form?.entryLimits?.maxEntries ? (
              <Content>{fields?.form?.entryLimits?.limitReachedMessage}</Content>
            ) : (
              <Form
                className="form-download"
                generate
                data={fields.form}
                scrollToConfirm={false}
                renderLoading={() => <Loading />}
                renderStatus={({ status, content }) => {
                  setFormStatus(status)

                  return <Content>{content}</Content>
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
