import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Microcopy from '~/components/elements/Microcopy'
import Share from '~/components/elements/Share'
import type { Page, Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerContentInBlock({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<LoaderData<Page>>()

  let featuredImage = null

  if (fields.showFeaturedImage) {
    featuredImage = page.featuredImage?.node
  }

  return (
    <section data-component="BannerContentInBlock" id={fields.sectionId ?? undefined} className="pb-12 lg:py-12">
      <div className="container">
        {fields.imagePosition === 'left' && (fields.image || featuredImage) ? (
          <>
            <div className="grid grid-cols-5 items-start gap-6 lg:gap-12">
              <div className="col-span-5 lg:col-span-2">
                {fields.image && !fields.showFeaturedImage && <LossePlaatjie src={fields.image} className="max-h-[285px] w-auto" />}
                {featuredImage && fields.showFeaturedImage && (
                  <div className="relative">
                    <img src="/images/eo-mascot-featuredimage.png" alt="eo-mascot-featuredimage" className="mx-auto" />
                    <LossePlaatjie
                      maxwidth={150}
                      src={featuredImage}
                      className="absolute top-[32%] right-[19%] w-[23%] md:max-lg:w-[16%] md:max-lg:right-[27%] h-auto -rotate-[21deg]"
                    />
                  </div>
                )}
              </div>
              <div className="col-span-5 lg:col-span-3 self-center">
                <Content
                  className={clsx(
                    fields.titleColor === 'orange' ? 'children-headings:text-eo-buizel' : 'children-headings:text-eo-whiscash',
                    'children-headings:text-4xl lg:children-headings:text-7xl max-lg:text-center lg:mb-14'
                  )}
                >
                  {fields.title}
                </Content>
              </div>
            </div>
            <div className="shadow-lg rounded-3xl bg-white px-6 py-5 lg:px-40 lg:pt-36 lg:pb-20 lg:-mt-24">
              <Content className="children-headings:mb-2 children-headings:text-xl lg:children-headings:text-3xl children-headings:font-montserrat children-headings:font-semibold">
                {fields.description}
              </Content>
              {fields.links && (
                <div className="mt-6 lg:mt-12">
                  {fields.links.map((edge) => {
                    return <LosseLink key={edge?.link?.title} src={edge?.link} className="btn--blue" />
                  })}
                </div>
              )}
            </div>
          </>
        ) : (
          <div
            className={clsx(
              !fields.image && 'mx-auto',
              'relative bg-white shadow-lg rounded-3xl lg:px-24 lg:py-20 lg:w-[92.5%] lg:mt-14 p-4 md:p-6'
            )}
          >
            {fields.image && (
              <LossePlaatjie
                maxwidth={500}
                src={fields.image}
                className="lg:absolute -right-20 lg:-top-20 rounded-2xl w-auto h-auto max-h-[325px]"
              />
            )}
            <div className={clsx(fields.image ? 'lg:w-[70%]' : 'lg:w-[95%] mx-auto')}>
              <div className="flex flex-col lg:flex-row justify-between items-end">
                <Content className="max-lg:mt-6 children-headings:text-2xl lg:children-headings:text-5xl children-headings:text-eo-buizel mb-4 lg:mb-10 lg:pr-16">
                  {fields.title}
                </Content>
                {!fields.image && (
                  <div className="hidden lg:flex justify-end mb-10">
                    <Share />
                  </div>
                )}
              </div>

              <Content className="hover:[&_a]:underline children-img:max-h-[550px] [&_a]:text-eo-buizel children-img:rounded-xl children-headings:text-2xl children-headings:font-montserrat children-headings:font-semibold children-strong:!font-semibold children-b:!font-semibold">
                {fields.description}
              </Content>
            </div>
            {fields.links && (
              <div className="mt-9 mx-4 lg:mx-16">
                {fields.links.map((edge) => {
                  return (
                    <div
                      key={edge?.link?.title}
                      className={clsx('flex flex-col items-center justify-center lg:justify-start mt-7 lg:mt-14 lg:items-center')}
                    >
                      <div>
                        <LosseLink className="btn--blue" key={edge?.link?.title} src={edge?.link} />
                      </div>
                      {edge?.microcopy && <Microcopy text={edge?.microcopy} />}
                    </div>
                  )
                })}
              </div>
            )}
            <div className="lg:hidden flex justify-center mb-10 mt-10">
              <Share />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
