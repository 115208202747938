import type { Maybe, Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { HeaderLoaderData } from './Header'
import { AnimatePresence, motion } from 'framer-motion'
import useHeader from '~/hooks/useHeader'
import useMediaQuery from '~/hooks/useMediaQuery'
import LogoDark from '~/components/elements/LogoDark'
import useCustomLayout from '~/hooks/useCustomLayout'

export default function HeaderSubmenu({
  submenu,
  posts,
  showPosts,
  showResume,
  parentUrl
}: {
  submenu: Maybe<Component_Header_MenuHeader_Submenu>[]
  posts: any[] | undefined
  showPosts: boolean
  showResume: boolean
  parentUrl: Maybe<string | undefined>
}) {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { sticky } = useCustomLayout()
  const { setCurrentMenuItemIndex } = useHeader()
  const isDesktop = useMediaQuery('(min-width: 1280px)')

  return (
    <div className="pt-12 relative z-40">
      <div className="xl:pt-3">
        {submenu && !showPosts && isDesktop && (
          <div className="p-7">
            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0, y: -20, pointerEvents: 'none' }}
                animate={{ opacity: 1, y: 0, pointerEvents: 'auto' }}
                exit={{ opacity: 0, y: 20, pointerEvents: 'none' }}
                className="bg-white relative rounded-3xl p-9"
              >
                <svg
                  onClick={() => {
                    setCurrentMenuItemIndex(-1)
                  }}
                  className="absolute xl:hidden block top-4 right-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                >
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 20L4 4m16 0L4 20" />
                </svg>
                <ul className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-7">
                  {submenu.map((edge) => {
                    return (
                      <li className="col-span-1" key={edge?.link?.title}>
                        <LosseLink to={edge?.link?.url || '/'} className="group flex items-center">
                          {edge?.image && <LossePlaatjie maxwidth={56} src={edge?.image} className="w-14 h-14 mr-7" />}
                          <span
                            className={clsx(
                              !edge?.link?.url?.includes('#') &&
                                'group-aria-current-page:bg-eo-buizel/20 group-aria-current-page:underline',
                              'inline-block font-co-headline text-eo-beautifly group-hover:underline text-[15px] hyphens-auto group-hover:bg-eo-buizel/20 px-2 py-1 rounded-lg'
                            )}
                          >
                            {edge?.link?.title}
                          </span>
                        </LosseLink>
                      </li>
                    )
                  })}
                </ul>
              </motion.div>
            </AnimatePresence>
          </div>
        )}
        <AnimatePresence>
          {submenu && !showPosts && !isDesktop && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ bounce: 0 }}
              className={clsx(sticky ? '-top-4' : 'top-0', 'bg-eo-nincada fixed left-0 w-full h-full z-[99]')}
            >
              <div className="relative h-full flex items-end">
                <svg
                  onClick={() => {
                    setCurrentMenuItemIndex(-1)
                  }}
                  className="absolute top-4 right-4 -rotate-90 smooth block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="m112 184l144 144l144-144"
                  />
                </svg>
                <LosseLink onClick={() => setCurrentMenuItemIndex(-1)} to="/" className="absolute left-4 top-4">
                  <LogoDark className="w-28 h-auto" />
                </LosseLink>
                <ul className="flex flex-col w-[85%] h-[92%] overflow-y-scroll mx-auto py-7 no-scrollbar">
                  {submenu.map((edge) => {
                    return (
                      <li key={edge?.link?.title}>
                        <LosseLink to={edge?.link?.url || '/'} className="group flex items-center text-2xl py-2">
                          {edge?.image && <LossePlaatjie maxwidth={64} src={edge?.image} className="w-14 h-14 md:w-16 md:h-16 mr-7" />}
                          <span
                            className={clsx(
                              !edge?.link?.url?.includes('#') &&
                                'group-aria-current-page:bg-eo-buizel/20 group-aria-current-page:underline',
                              'inline-block font-co-headline text-eo-beautifly group-hover:underline text-[15px] md:text-xl hyphens-auto group-hover:bg-eo-buizel/20 px-2 py-1 rounded-lg'
                            )}
                          >
                            {edge?.link?.title}
                          </span>
                        </LosseLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {posts && showPosts && isDesktop && (
          <div className="grid grid-cols-4 items-start p-7">
            <div className="col-span-4 xl:col-span-3">
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className="bg-white max-h-[80vh] relative no-scrollbar overflow-y-scroll rounded-3xl p-5 xl:p-9"
                >
                  <svg
                    onClick={() => {
                      setCurrentMenuItemIndex(-1)
                    }}
                    className="absolute xl:hidden block top-4 right-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                  >
                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 20L4 4m16 0L4 20" />
                  </svg>
                  <ul
                    className={clsx(
                      posts.length > 8 ? 'grid-cols-1 sm:grid-cols-2 xl:grid-cols-3' : 'grid-cols-1 sm:grid-cols-2 xl:grid-cols-4',
                      'grid gap-x-10 gap-y-5 xl:gap-y-7'
                    )}
                  >
                    {posts.map((edge) => {
                      const post = edge.node
                      const scrollId = post.title.toLowerCase().replace(/ /g, '-')

                      let showPost = true
                      const advancedPosttypeSettings = post.advancedPosttypeSettings

                      if (advancedPosttypeSettings) {
                        if (advancedPosttypeSettings.excludePageFromSite) {
                          showPost = false
                        }
                      }

                      return (
                        <li className="col-span-1" key={post.title}>
                          <LosseLink
                            to={showPost ? post.uri : `${parentUrl}#${scrollId}`}
                            className={clsx(
                              showResume
                                ? 'flex-row xl:gap-0 gap-2 items-center xl:items-start xl:flex-col h-full hover:bg-eo-buizel/20 aria-current-page:bg-eo-buizel/20 px-2 py-1 rounded-xl'
                                : 'flex-row',
                              'group flex items-start'
                            )}
                          >
                            <AnimatePresence>
                              <motion.div>
                                <LossePlaatjie
                                  maxwidth={56}
                                  src={post.featuredImage.node}
                                  className={clsx(showResume ? 'w-14 h-14 mb-3' : 'w-7 h-7', 'object-contain mr-5 rounded-xl')}
                                />
                              </motion.div>
                            </AnimatePresence>

                            <span
                              className={clsx(
                                showResume && 'mb-2',
                                !showResume && 'px-2 py-1 group-hover:bg-eo-buizel/20',
                                !showResume && showPost && ' group-aria-current-page:bg-eo-buizel/20 group-aria-current-page:underline',
                                'inline-block font-co-headline text-eo-beautifly group-hover:underline text-[15px] hyphens-auto rounded-lg w-full'
                              )}
                            >
                              {post.title}
                            </span>
                            {showResume && post.recap.resume && (
                              <div className="xl:block hidden mt-auto">
                                <Content className="line-clamp-5 children-p:text-sm">{post.recap.resume}</Content>
                              </div>
                            )}
                          </LosseLink>
                        </li>
                      )
                    })}
                  </ul>
                </motion.div>
              </AnimatePresence>
            </div>
            <div key={header.ctaHeader?.image?.mediaItemUrl} className="col-span-1 hidden xl:flex flex-col h-full">
              <div className="relative flex justify-center items-center mt-28">
                <LossePlaatjie maxwidth={500} src={header.ctaHeader?.image} />
                <div className="absolute mt-20">
                  <LosseLink src={header.ctaHeader?.link} className="btn--white text-sm" />
                </div>
              </div>
            </div>
          </div>
        )}
        <AnimatePresence>
          {posts && showPosts && !isDesktop && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ bounce: 0 }}
              className={clsx(sticky ? '-top-4' : 'top-0', 'fixed left-0 w-full h-full bg-eo-nincada z-[99]')}
            >
              <div className="relative flex h-full items-end">
                <svg
                  onClick={() => {
                    setCurrentMenuItemIndex(-1)
                  }}
                  className="absolute top-4 right-4 -rotate-90 smooth block"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="m112 184l144 144l144-144"
                  />
                </svg>
                <LosseLink onClick={() => setCurrentMenuItemIndex(-1)} to="/" className="absolute left-4 top-4">
                  <LogoDark className="w-28 h-auto" />
                </LosseLink>
                <ul className="flex flex-col w-[85%] h-[92%] overflow-y-scroll mx-auto py-7 no-scrollbar">
                  {posts.map((edge) => {
                    const post = edge.node

                    const scrollId = post.title.toLowerCase().replace(/ /g, '-')

                    let showPost = true
                    const advancedPosttypeSettings = post.advancedPosttypeSettings

                    if (advancedPosttypeSettings) {
                      if (advancedPosttypeSettings.excludePageFromSite) {
                        showPost = false
                      }
                    }

                    return (
                      <li key={post.title}>
                        <LosseLink to={showPost ? post.uri : `${parentUrl}#${scrollId}`} className="group flex items-center text-2xl py-2">
                          {post.featuredImage && (
                            <AnimatePresence>
                              <motion.div>
                                <LossePlaatjie
                                  maxwidth={64}
                                  src={post.featuredImage.node ?? ''}
                                  className="w-10 h-10 md:w-16 md:h-16 object-contain mr-10 rounded-xl"
                                />
                              </motion.div>
                            </AnimatePresence>
                          )}

                          <span
                            style={{ lineHeight: 1.3 }}
                            className={clsx(
                              showPost && 'group-aria-current-page:bg-eo-buizel/20 group-aria-current-page:underline',
                              'group-hover:bg-eo-buizel/20 px-2 py-1 md:py-2 inline-block font-co-headline text-eo-beautifly group-hover:underline text-[15px] md:text-xl hyphens-auto rounded-lg w-full'
                            )}
                          >
                            {post.title}
                          </span>
                          {showResume && post.recap.resume && (
                            <div className="xl:block hidden mt-auto">
                              <Content className="line-clamp-5 children-p:text-sm">{post.recap.resume}</Content>
                            </div>
                          )}
                        </LosseLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}
