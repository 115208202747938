import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Teammembers, Teammember } from '~/graphql/types'

export default function TeammembersOverview({ fields }: { fields: Page_Flexcontent_Flex_Teammembers }) {
  const alphabeticallySortedPosts = fields.posts?.edges
    .slice()
    .sort(({ node: a }: { node: any }, { node: b }: { node: any }) => a.title.localeCompare(b.title))

  return (
    <section data-component="TeammembersOverview" id={fields.sectionId ?? undefined} className="py-12 lg:py-24">
      <div className="container">
        <div className="lg:w-3/4 mx-auto">
          <Content className="children-h2:text-3xl lg:children-h2:text-6xl children-h3:mb-2 children-h2:mb-4 lg:children-h2:mb-8 children-h2:text-eo-buizel children-h3:text-xl lg:children-h3:text-2xl children-h3:text-eo-stakataka">
            {fields.description}
          </Content>
          <div className="grid grid-cols-4 md:max-lg:grid-cols-6 mt-20 gap-x-4 lg:gap-x-6 gap-y-6 lg:gap-y-12">
            {alphabeticallySortedPosts?.map((edge) => {
              const post = edge.node as Teammember

              return (
                <div key={post.title} className="col-span-2 lg:col-span-1 flex flex-col items-center">
                  <div className="relative">
                    <LossePlaatjie maxwidth={160} src={post.featuredImage?.node} className="rounded-full w-40 h-40 object-cover mb-4" />
                    {post.recap?.linkedin && (
                      <LosseLink
                        className="absolute right-2 bottom-3 hover:opacity-75 transition-opacity"
                        to={post.recap?.linkedin}
                        target="_blank"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.9004 27.5569C21.5774 27.5569 27.8008 21.3881 27.8008 13.7785C27.8008 6.16882 21.5774 0 13.9004 0C6.22342 0 0 6.16882 0 13.7785C0 21.3881 6.22342 27.5569 13.9004 27.5569ZM6.70712 7.59856C6.70712 6.62691 7.45073 5.88737 8.58733 5.88737C9.72418 5.88737 10.4227 6.62691 10.4447 7.59856C10.4447 8.54958 9.72418 9.31049 8.56527 9.31049H8.54372C7.42917 9.31049 6.70712 8.54958 6.70712 7.59856ZM22.206 20.5677V14.8872C22.206 11.8452 20.5671 10.4295 18.3812 10.4295C16.6157 10.4295 15.8282 11.3923 15.3866 12.0658V10.6621H12.0636C12.1082 11.5913 12.0636 20.5675 12.0636 20.5675H15.3864V15.0353C15.3864 14.7383 15.4089 14.4443 15.4964 14.2326C15.7373 13.6402 16.2826 13.0284 17.2007 13.0284C18.4037 13.0284 18.8834 13.9371 18.8834 15.2679V20.5677H22.206ZM15.3866 12.0658V12.0982H15.3646C15.3677 12.0926 15.3719 12.0869 15.3761 12.0812L15.3761 12.0812L15.3761 12.0812C15.3798 12.0761 15.3835 12.071 15.3866 12.0658ZM6.9046 20.5677H10.2262V10.6621H6.9046V20.5677Z"
                            fill="#FF8300"
                          ></path>
                        </svg>
                      </LosseLink>
                    )}
                  </div>
                  <span className="text-eo-buizel font-semibold text-center">{post.title}</span>
                  <span className="text-eo-buizel text-center inline-block text-sm">{post.recap?.jobTitle}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
