import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'

export default function HeaderBrand() {
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <header className="relative flex justify-center">
      <div className="mx-4 xl:mx-20 xl:-mt-2">
        <LossePlaatjie maxwidth={1999} src={header.brandHeader} />
      </div>
    </header>
  )
}
