import ReactModal from 'react-modal'
import type { ReactNode } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import useMediaQuery from '~/hooks/useMediaQuery'

export default function Modal({
  isOpen,
  requestClose,
  bgColor = '#FFF',
  children
}: {
  isOpen: boolean
  requestClose: () => void
  bgColor?: string
  children: ReactNode
}) {
  const [body, setBody] = useState<HTMLBodyElement | null>(null)
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const customStyle = {
    content: {
      top: '55%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: bgColor,
      borderRadius: '1rem',
      maxHeight: '95vh',
      width: isDesktop ? '60vw' : '97.5vw',
      maxWidth: 900,
      minHeight: isDesktop ? '20vh' : '40vh'
    },
    overlay: {
      zIndex: 999,
      backgroundColor: 'rgba(103, 103, 103, 0.9)'
    }
  }

  useEffect(() => {
    setBody(document.querySelector('body'))
  }, [])

  if (!body) return null

  return (
    <ReactModal ariaHideApp appElement={body} style={customStyle} isOpen={isOpen} onRequestClose={requestClose}>
      <div className="relative sm:p-4 lg:p-12">
        <button onClick={requestClose} className="absolute right-1 top-1 hover:opacity-75 md:right-2 md:top-2 z-[99]">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="98 28 504 504" className="h-7 w-7">
            <path
              fill="#676767"
              fillRule="evenodd"
              d="M350 532c139.18 0 252-112.82 252-252S489.18 28 350 28 98 140.82 98 280s112.82 252 252 252zm131.8-383.8c10.938 10.934 10.938 28.66 0 39.594l-92.199 92.203L481.8 372.2c10.938 10.934 10.938 28.66 0 39.594-10.934 10.938-28.66 10.938-39.594 0l-92.203-92.199-92.203 92.199c-10.934 10.938-28.66 10.938-39.594 0-10.938-10.934-10.938-28.66 0-39.594l92.199-92.203-92.199-92.203c-10.938-10.934-10.938-28.66 0-39.594 10.934-10.938 28.66-10.938 39.594 0l92.203 92.199 92.203-92.199c10.934-10.938 28.66-10.938 39.594 0z"
            />
          </svg>
        </button>
        <div>{children}</div>
      </div>
    </ReactModal>
  )
}
