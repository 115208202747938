import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentLinkBlocks from './ContentLinkBlocks'
import ContentImage from './ContentImage'
import ContentTitleImage from './ContentTitleImage'
import ContentAccordion from './ContentAccordion'
import ContentColumns from './ContentColumns'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    link_blocks: ContentLinkBlocks,
    image: ContentImage,
    title_image: ContentTitleImage,
    accordion: ContentAccordion,
    columns: ContentColumns
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
