import { motion } from 'framer-motion'

export default function Fingerprint({ className }: { className?: string }) {
  return (
    <svg
      width="748"
      height="748"
      viewBox="0 0 598 748"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? undefined}
      style={{ maxWidth: '100%', pointerEvents: 'none' }}
    >
      <g opacity="0.75">
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.5
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-75.6401 168.563C-73.7142 169.971 -71.478 170.649 -69.2611 170.649C-65.9187 170.649 -62.6226 169.107 -60.4992 166.196C-8.57874 95.0093 67.6019 46.204 154.012 28.7711C230.688 13.2981 308.23 23.3053 378.255 57.6993C383.63 60.3383 390.121 58.1187 392.758 52.742C395.396 47.3671 393.18 40.8693 387.806 38.2294C313.453 1.71376 231.13 -8.91291 149.73 7.51338C57.9941 26.0185 -22.8842 77.835 -78.0073 153.414C-81.5341 158.25 -80.475 165.033 -75.6401 168.563"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.55
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M589.698 299.844C589.549 299.102 589.326 298.393 589.04 297.722C569.654 204.31 516.102 122.774 438.033 67.9557C433.129 64.5146 426.373 65.7039 422.935 70.6017C419.5 75.5065 420.682 82.2682 425.581 85.7076C499.683 137.741 550.354 215.324 568.257 304.167C568.387 304.816 568.574 305.438 568.809 306.031C573.643 330.721 575.873 355.868 575.395 380.816C575.278 386.8 580.039 391.749 586.023 391.864C586.092 391.866 586.163 391.866 586.235 391.866C592.122 391.866 596.951 387.146 597.064 381.229C597.586 354.068 595.106 326.688 589.698 299.844"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.025
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M234.239 371.56C233.056 365.689 227.329 361.877 221.472 363.074C215.605 364.259 211.807 369.977 212.992 375.846C237.713 498.52 204.469 622.881 121.787 717.049C117.837 721.546 118.278 728.397 122.774 732.351C124.834 734.16 127.383 735.048 129.924 735.048C132.931 735.048 135.927 733.801 138.069 731.359C225.254 632.069 260.307 500.926 234.239 371.56"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.05
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M165.624 575.977C160.049 573.796 153.765 576.56 151.589 582.138C134.264 626.559 108.598 666.406 75.3052 700.57C71.1248 704.858 71.2122 711.722 75.4974 715.905C77.6043 717.958 80.335 718.984 83.0622 718.984C85.8821 718.984 88.6984 717.889 90.821 715.71C126.156 679.453 153.394 637.167 171.781 590.02C173.955 584.444 171.2 578.156 165.624 575.977"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.075
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.483 372.138L280.719 372.061L278.803 362.554C272.658 332.053 242.855 312.236 212.376 318.394C181.896 324.544 162.098 354.361 168.247 384.863L170.313 395.125L170.487 395.094C178.11 439.5 177.22 484.17 167.823 528.042C166.569 533.899 170.296 539.661 176.148 540.914C182.004 542.17 187.762 538.439 189.014 532.585C199.765 482.397 200.034 431.238 189.811 380.522L188.869 375.85C187.809 358.807 199.476 343.12 216.66 339.651C234.379 336.09 251.703 346.739 256.78 363.732L257.417 366.886C282.994 493.797 251.912 625.162 172.14 727.301C168.454 732.018 169.29 738.833 174.006 742.52C175.986 744.071 178.337 744.822 180.67 744.822C183.892 744.822 187.079 743.391 189.218 740.655C270.895 636.077 303.907 502.284 280.483 372.138"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.1
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148.946 410.6C148.142 404.668 142.674 400.507 136.75 401.311C130.82 402.116 126.664 407.578 127.468 413.513C140.762 511.665 105.382 610.811 32.8299 678.731C28.4582 682.82 28.2301 689.683 32.3196 694.056C34.4535 696.338 37.3407 697.492 40.2357 697.492C42.8877 697.492 45.5468 696.524 47.6379 694.567C125.309 621.859 163.179 515.701 148.946 410.6"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.125
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M323.188 353.6C317.82 326.963 302.409 304.009 279.788 288.97C257.168 273.932 230.046 268.603 203.426 273.981C163.479 282.041 132.291 313.04 123.968 352.951C122.746 358.815 126.503 364.558 132.363 365.78C138.229 367.007 143.963 363.242 145.185 357.381C151.734 325.975 176.276 301.583 207.709 295.24C228.664 291.017 249.994 295.201 267.794 307.034C284.415 318.085 296.093 334.57 301.004 353.774L301.84 357.924C308.2 389.486 311.468 421.654 311.558 453.535C311.574 459.517 316.424 464.35 322.396 464.35H322.424C328.412 464.334 333.249 459.465 333.232 453.478C333.147 423.277 330.333 392.837 324.856 362.852L325.041 362.784L323.188 353.6Z"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.15
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M320.437 500.247C314.486 499.574 309.121 503.845 308.44 509.794C299.492 588.336 271.038 664.539 226.157 730.165C222.777 735.108 224.042 741.856 228.981 745.236C230.851 746.518 232.982 747.132 235.091 747.132C238.548 747.132 241.946 745.483 244.044 742.412C290.913 673.881 320.627 594.291 329.978 512.251C330.654 506.299 326.384 500.927 320.437 500.247"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.175
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.966 398.445L99.7374 393.018C89.4524 327.203 132.871 264.124 198.761 250.828C210.191 248.526 221.82 247.81 233.327 248.707C239.326 249.186 244.51 244.706 244.975 238.739C245.437 232.767 240.979 227.55 235.01 227.086C221.509 226.034 207.873 226.868 194.478 229.569C115.044 245.598 63.4533 323.297 79.4689 402.778L80.9867 409.467C96.7009 499.617 63.9033 592.11 -5.55493 652.304C-10.0788 656.223 -10.5716 663.069 -6.65246 667.599C-4.50895 670.074 -1.49249 671.343 1.54495 671.343C4.05897 671.343 6.58434 670.472 8.63348 668.695C85.7177 601.9 121.098 498.344 100.966 398.445"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.2
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M369.174 353.275L369.302 353.22L367.573 344.647C359.07 302.449 332.625 266.216 295.023 245.24C289.799 242.324 283.195 244.201 280.28 249.43C277.367 254.662 279.242 261.266 284.47 264.182C315.322 281.394 337.367 310.653 345.445 344.895L346.264 348.962C372.214 477.719 349.941 610.994 283.555 724.234C280.526 729.398 282.255 736.041 287.418 739.071C289.143 740.082 291.029 740.564 292.892 740.564C296.612 740.564 300.232 738.648 302.249 735.203C369.863 619.88 393.506 484.591 369.174 353.275"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.225
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.2123 540.273C47.3794 534.69 44.6137 528.407 39.0361 526.239C33.4497 524.073 27.1747 526.84 25.0102 532.421C11.4964 567.238 -10.8947 598.138 -39.7451 621.784C-44.3738 625.58 -45.0528 632.412 -41.2603 637.045C-39.1177 639.661 -36.0069 641.017 -32.8698 641.017C-30.4554 641.017 -28.0244 640.213 -26.0094 638.562C5.72296 612.554 30.3493 578.563 45.2123 540.273"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.25
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M415.964 551.848C427.388 482.454 426.473 412.048 413.255 342.45L413.313 342.404L411.962 335.695C391.009 231.727 289.428 164.201 185.528 185.157C81.6208 206.121 14.1304 307.765 35.0822 411.737L36.4507 417.894C39.8481 437.655 40.4991 457.654 38.3836 477.444C37.7483 483.397 42.0554 488.739 48.008 489.377C53.9692 490.032 59.2987 485.704 59.9358 479.746C62.5066 455.685 61.3645 431.345 56.54 407.405L55.2695 401.689C40.2466 311.477 99.5396 224.632 189.813 206.416C280.938 188.031 370.069 246.379 390.054 336.836L390.691 339.999C404.633 409.204 405.943 479.291 394.577 548.321C393.605 554.229 397.602 559.809 403.509 560.785C404.108 560.882 404.698 560.93 405.285 560.93C410.493 560.93 415.089 557.157 415.964 551.848"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.275
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M395.89 596.07C390.124 594.52 384.169 597.951 382.62 603.743C373.085 639.377 360.039 674.312 343.841 707.579C341.219 712.963 343.455 719.451 348.837 722.075C350.364 722.823 351.981 723.174 353.575 723.174C357.588 723.174 361.448 720.932 363.324 717.077C380.128 682.567 393.663 646.323 403.558 609.35C405.107 603.567 401.674 597.618 395.89 596.07"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.3
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M455.569 322.88L455.53 322.848C448.45 290.443 435.03 260.515 415.608 233.84C412.082 229.001 405.306 227.938 400.465 231.462C395.628 234.987 394.563 241.77 398.088 246.61C416.435 271.808 428.889 300.21 435.101 331.029L435.57 333.314C458.381 448.706 448.344 567.463 406.506 676.909C404.369 682.504 407.168 688.772 412.758 690.912C414.031 691.399 415.341 691.629 416.626 691.629C420.988 691.629 425.102 688.977 426.751 684.655C470.333 570.651 480.572 446.888 456.36 326.752L455.569 322.88Z"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.325
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.579 140.746C158.522 144.389 140.959 150.092 124.372 157.7C118.932 160.194 116.542 166.632 119.037 172.076C121.532 177.52 127.963 179.91 133.403 177.416C148.475 170.504 164.442 165.318 180.863 162.006C244.596 149.152 310.034 165.44 360.386 206.702C362.398 208.354 364.829 209.158 367.246 209.158C370.38 209.158 373.493 207.802 375.634 205.185C379.428 200.552 378.747 193.719 374.116 189.926C318.715 144.522 246.71 126.593 176.579 140.746"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.35
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1141 416.367L10.8427 410.542C-2.5261 333.439 26.5489 255.497 87.5729 205.913C92.2165 202.137 92.9252 195.307 89.152 190.66C85.3787 186.008 78.5515 185.301 73.908 189.078C5.06226 245.018 -26.8239 333.768 -9.30528 420.693L-8.12386 426.103C2.1655 487.154 -21.1265 548.641 -69.7028 587.689C-74.3682 591.441 -75.1109 598.266 -71.3613 602.932C-69.2204 605.601 -66.0781 606.986 -62.9079 606.986C-60.5267 606.986 -58.1271 606.204 -56.1269 604.595C0.236084 559.289 26.3837 487.164 12.1141 416.367"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.375
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M479.486 322.074L480.508 326.998C482.428 336.902 484.137 346.888 485.598 356.752C486.397 362.136 491.022 366.004 496.306 366.004C496.835 366.004 497.369 365.967 497.909 365.886C503.829 365.008 507.918 359.493 507.04 353.569C505.28 341.703 503.174 329.666 500.783 317.792L499.115 309.766L498.993 309.755C464.641 161.387 317.781 66.0345 167.629 96.3355C110.666 107.827 59.3976 136.026 19.3656 177.877C-19.7506 218.773 -45.5269 269.754 -55.1758 325.307C-56.2008 331.208 -52.2511 336.82 -46.3562 337.848C-40.4535 338.882 -34.8461 334.92 -33.8228 329.021C-24.9141 277.725 -1.10568 230.646 35.0229 192.874C71.9781 154.237 119.313 128.206 171.912 117.593C313.053 89.1168 451.03 180.852 479.486 322.074"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.4
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M501.661 401.465C495.685 401.844 491.152 406.992 491.527 412.972C496.069 484.934 488.952 556.757 470.386 626.444C468.844 632.23 472.282 638.172 478.066 639.713C478.999 639.963 479.94 640.081 480.865 640.081C485.655 640.081 490.034 636.879 491.329 632.029C510.5 560.075 517.845 485.915 513.16 411.605C512.784 405.628 507.624 401.134 501.661 401.465"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.425
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-76.7324 434.291L-77.9191 428.671C-92.2455 350.212 -75.918 270.665 -31.689 204.055C13.6035 135.847 82.7305 89.3684 162.964 73.1833C196.655 66.3849 230.841 65.2515 264.574 69.8112C270.501 70.623 275.962 66.4531 276.763 60.518C277.565 54.5829 273.405 49.1232 267.473 48.3211C231.349 43.4363 194.74 44.6475 158.679 51.9239C72.7732 69.2572 -1.24557 119.023 -49.7397 192.053C-98.2365 265.088 -115.405 352.648 -98.081 438.607L-97.3723 441.966C-93.5877 466.095 -101.105 490.908 -117.823 508.951C-121.894 513.347 -121.633 520.206 -117.242 524.277C-115.157 526.213 -112.515 527.169 -109.879 527.169C-106.967 527.169 -104.063 526 -101.928 523.697C-79.7017 499.704 -70.2826 466.281 -76.7324 434.291"
          fill="white"
        />
        <motion.path
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            delay: 0.45
          }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M543.258 299.6L543.12 299.607C517.106 187.282 433.681 96.3436 323.42 60.9762C317.728 59.1472 311.62 62.2904 309.795 67.9904C307.967 73.6939 311.104 79.7994 316.806 81.6283C422.592 115.563 501.934 204.266 523.871 313.121L525.203 319.421C539.445 393.453 541.983 468.594 532.741 542.88C532.003 548.823 536.217 554.239 542.156 554.981C542.611 555.035 543.061 555.063 543.509 555.063C548.899 555.063 553.569 551.047 554.251 545.559C564.043 466.841 560.999 387.193 545.207 308.83L543.258 299.6Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
