import { LosseLink, useLoaderData, useLocation } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import Fingerprint from '~/components/elements/Fingerprint'
import clsx from 'clsx'
import useHeader from '~/hooks/useHeader'
import useMediaQuery from '~/hooks/useMediaQuery'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { pathname } = useLocation()
  const { setCurrentMenuItemIndex, currentMenuItemIndex } = useHeader()
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const parentPath = pathname.split('/')[1]

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        const partiallyCurrent = edge?.link?.url?.replace(/\//g, '') === parentPath

        return (
          <li
            onMouseEnter={() => {
              if (isDesktop) {
                setCurrentMenuItemIndex(index)
              }
            }}
            className="relative mx-2 xl:mx-7 mb-5 xl:mb-0"
            key={index}
          >
            <div className="flex flex-wrap justify-between items-center">
              <LosseLink
                className="group relative text-2xl xl:text-base text-left xl:text-center font-semibold xl:leading-4 text-white"
                to={edge?.link?.url || '/'}
              >
                <span
                  className={clsx(
                    partiallyCurrent ? 'opacity-100' : 'opacity-80 xl:opacity-75',
                    'group-hover:opacity-100 transition-opacity'
                  )}
                >
                  {edge?.link?.title}
                </span>
                {/* <div
                  className={clsx(
                    partiallyCurrent ? 'opacity-100' : 'opacity-0',
                    'absolute pointer-events-none -right-4 -top-4 transition-opacity'
                  )}
                >
                  <Fingerprint key={`fingerprint-${pathname}`} className="h-9 w-9" />
                </div> */}
              </LosseLink>
              {(edge?.submenu || edge?.showPosttype) && (
                <svg
                  onClick={() => {
                    setCurrentMenuItemIndex(index)
                  }}
                  className={clsx(currentMenuItemIndex === index ? '' : '-rotate-90', 'smooth xl:hidden block')}
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="#FFFFFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="48"
                    d="m112 184l144 144l144-144"
                  />
                </svg>
              )}
            </div>
          </li>
        )
      })}
    </>
  )
}
