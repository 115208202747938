import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import SolutionsOverview from './SolutionsOverview'
import SolutionsHighlighted from './SolutionsHighlighted'

export default function SolutionsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: SolutionsOverview,
    highlighted: SolutionsHighlighted
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
