import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerLanding from './BannerLanding'
import BannerCta from './BannerCta'
import BannerContentInBlock from './BannerContentInBlock'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    landing: BannerLanding,
    default: BannerDefault,
    cta: BannerCta,
    content_in_block: BannerContentInBlock
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
