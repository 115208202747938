import { useLoaderData, type LoaderData, useLocation } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import type { Component_Header, Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import Logo from '~/components/elements/Logo'
import HeaderTopmenu from './HeaderTopmenu'
import HeaderBrand from './HeaderBrand'
import useCustomLayout from '~/hooks/useCustomLayout'
import clsx from 'clsx'
import HeaderSticky from './HeaderSticky'
import { AnimatePresence, motion } from 'framer-motion'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect } from 'react'
import { createContext, useState } from 'react'
import HeaderSubmenu from './HeaderSubmenu'
import useMediaQuery from '~/hooks/useMediaQuery'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export const HeaderContext = createContext<{
  currentMenuItemIndex: number
  setCurrentMenuItemIndex: Dispatch<SetStateAction<number>>
}>({
  currentMenuItemIndex: -1,
  setCurrentMenuItemIndex: (n) => n
})

export default function Header() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { sticky } = useCustomLayout()
  const { pathname, hash } = useLocation()
  const [currentMenuItemIndex, setCurrentMenuItemIndex] = useState(-1)
  const isMobile = useMediaQuery('(max-width: 1023px)')

  useEffect(() => {
    setCurrentMenuItemIndex(-1)
  }, [pathname, sticky, hash])

  useEffect(() => {
    const { documentElement } = document

    if (isMobile) {
      if (currentMenuItemIndex !== -1) {
        documentElement.classList.add('overflow-hidden')
      } else {
        documentElement.classList.remove('overflow-hidden')
      }
    }
  }, [currentMenuItemIndex, isMobile])

  const currentHoveredItem = header.menuHeader![currentMenuItemIndex]
  const submenuOpen = currentMenuItemIndex !== -1 && (currentHoveredItem?.submenu || currentHoveredItem?.posts)

  return (
    <HeaderContext.Provider value={{ currentMenuItemIndex, setCurrentMenuItemIndex }}>
      <div data-component="Header" className="relative max-xl:z-[99]">
        <div className="absolute top-0 h-[120%] md:h-[130%] xl:h-[200%] w-full bg-gradient-to-b from-eo-ribombee via-eo-ribombee" />
        <nav className="w-full relative z-[2]">
          <div className="container">
            <HeaderTopmenu />
            <div className="relative">
              <div className="absolute h-full w-full top-0 left-0 bg-gradient-primary rounded-full" />
              <div className={clsx('relative z-40 smooth flex h-full items-center justify-between rounded-full px-6 xl:px-8 py-2 xl:py-4')}>
                <LosseLink
                  to="/"
                  aria-label="Home"
                  className="hover:opacity-90 transition-opacity"
                  onMouseEnter={() => setCurrentMenuItemIndex(-1)}
                >
                  <Logo className="w-3/4 h-auto xl:w-auto" />
                </LosseLink>
                <HeaderDesktop />
                <HeaderMobile />
              </div>

              <AnimatePresence>
                {(currentHoveredItem?.submenu || currentHoveredItem?.posts) && !sticky && (
                  <motion.div
                    key={`submenu_${currentMenuItemIndex}`}
                    onMouseLeave={() => setCurrentMenuItemIndex(-1)}
                    className="absolute left-0 z-[100] xl:z-20 w-full top-0 rounded-[2.75rem]"
                  >
                    <div
                      className={clsx(
                        submenuOpen ? 'opacity-100' : 'opacity-0',
                        'absolute z-30 xl:block hidden smooth bg-gradient-primary w-full h-full rounded-[2.75rem] top-0 left-0'
                      )}
                    />
                    <HeaderSubmenu
                      parentUrl={currentHoveredItem.link?.url}
                      submenu={currentHoveredItem?.submenu as Component_Header_MenuHeader_Submenu[]}
                      posts={currentHoveredItem?.posts?.edges}
                      showPosts={currentHoveredItem?.showPosttype || false}
                      showResume={currentHoveredItem?.showResume || false}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </nav>
        <HeaderBrand />
        <AnimatePresence>
          {sticky && (
            <motion.div
              initial={{ opacity: 0, y: '-100%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-100%' }}
              className={clsx(submenuOpen && 'h-full', 'fixed top-4 w-full z-[99]')}
            >
              <HeaderSticky />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </HeaderContext.Provider>
  )
}
