import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { Mousewheel, Navigation } from 'swiper'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderOverflow({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const sliderId = fields.sliderId
  const prevClass = `${sliderId}-button-prev`
  const nextClass = `${sliderId}-button-next`

  return (
    <section data-component="SliderOverflow" id={fields.sectionId ?? undefined} className="py-10 lg:py-20">
      <div className="container">
        <Content className="mb-4 lg:mb-12 children-headings:text-4xl lg:children-headings:text-7xl children-headings:text-eo-buizel">
          {fields.description}
        </Content>
        <div className="relative">
          <LossePlaatjie
            maxwidth={422}
            src={fields.image}
            className="absolute bottom-4 -left-14 lg:-left-12 xl:-left-32 lg:top-4 lg:bottom-[unset] z-[2] max-sm:w-44 max-lg:w-56 max-xl:w-80 xl:w-[422px]"
          />
          <div className="ml-20 lg:ml-52 -mr-4 md:-mr-10 lg:-mr-0">
            <Slider
              className="!pr-4"
              modules={[Navigation, Mousewheel]}
              navigation={{
                prevEl: `.${prevClass}`,
                nextEl: `.${nextClass}`
              }}
              mousewheel={{
                forceToAxis: true
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1.33,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 2.33,
                  spaceBetween: 20
                },
                1024: {
                  slidesPerView: 2.66,
                  spaceBetween: 20
                },
                1280: {
                  slidesPerView: 3.33,
                  spaceBetween: 20
                }
              }}
            >
              {fields.slides?.map((edge) => {
                return (
                  <Slide key={edge?.title} className="mb-8 !h-auto">
                    <LosseLink
                      to={edge?.link?.url}
                      target={edge?.link?.target || '_self'}
                      className="flex h-full flex-col overflow-hidden rounded-3xl bg-white py-5 lg:py-7 px-2 lg:px-6 shadow-xl"
                    >
                      <div className="mb-2 flex h-1/3 items-center">
                        <h2 className="text-center max-sm:leading-5 text-lg sm:text-xl lg:text-3xl text-eo-ledian">{edge?.title}</h2>
                      </div>
                      <div className="flex h-full items-center">
                        <LossePlaatjie
                          maxwidth={750}
                          src={edge?.image}
                          className="mx-auto max-w-[50%] lg:min-w-[75%] h-auto lg:max-w-full"
                        />
                      </div>
                      <div className="mt-auto flex justify-end">
                        <span className="btn--blue--transparent mt-6 text-sm">{edge?.link?.title}</span>
                      </div>
                    </LosseLink>
                  </Slide>
                )
              })}
            </Slider>
            <div className="flex justify-end lg:mt-6 mr-4 lg:mr-0">
              <button
                className={clsx(
                  prevClass,
                  'custom-slider-default-btn-prev rotate-180 mr-4 [&:not([disabled])]:hover:scale-95 transition-transform'
                )}
              >
                <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="26.5" cy="26.5" r="26.5" fill="#FF8300" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 14V39L37 26.5011L21 14Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-[0px] absolute">Vorige</span>
              </button>
              <button className={clsx(nextClass, '[&:not([disabled])]:hover:scale-95 transition-transform')}>
                <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="26.5" cy="26.5" r="26.5" fill="#FF8300" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 14V39L37 26.5011L21 14Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="text-[0px] absolute">Volgende</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
