import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import CtaDefault from './CtaDefault'
import CtaImage from './CtaImage'
import CtaForm from './CtaInBlockForm'
import CtaTitle from './CtaTitle'
import CtaInBlock from './CtaInBlock'

export default function CtaShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: CtaDefault,
    image: CtaImage,
    in_block_form: CtaForm,
    in_block: CtaInBlock,
    title: CtaTitle
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
