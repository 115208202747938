import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import TeammembersOverview from './TeammembersOverview'

export default function TeammembersShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: TeammembersOverview
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
