import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Case, Page_Flexcontent_Flex_Cases } from '~/graphql/types'

export default function CasesHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Cases }) {
  if (!fields.highlighted) return null

  const highlightedCase = fields.highlighted[0] as unknown as Case

  return (
    <section data-component="CasesHighlighted" id={fields.sectionId ?? undefined} className="relative my-8 lg:my-24">
      <div className="absolute bg-eo-buizel h-[95%] lg:h-[90%] w-full top-[5%]" />
      <div className="relative">
        <div className="container">
          <div className="absolute bottom-[5%] right-0 flex justify-end overflow-hidden mt-4 h-[85%]">
            <svg
              width="746"
              height="100%"
              viewBox="0 0 746 445"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative max-w-full"
            >
              <g opacity="0.142578">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M694.645 95.4728C694.645 47.1794 655.488 8 607.187 8C558.856 8 519.709 47.1794 519.709 95.4728C519.709 143.786 558.856 182.935 607.187 182.935C655.488 182.935 694.645 143.786 694.645 95.4728Z"
                  stroke="#EB0045"
                  strokeWidth="15"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M138.515 182.935C186.839 182.935 225.968 143.786 225.968 95.4728C225.968 47.1794 186.839 8 138.515 8C90.1915 8 51.0322 47.1794 51.0322 95.4728C51.0322 143.786 90.1915 182.935 138.515 182.935Z"
                  stroke="#EB0045"
                  strokeWidth="15"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.3694 443L9.52245 340.809C-0.355056 273.376 39.3559 245.16 84.483 234.41C107.082 229.034 150.892 224.797 189.95 217.378C242.634 207.372 301.155 167.691 338.736 86.4579C349.709 62.7421 368.62 58.8666 384.958 64.6043C405.507 71.8016 409.154 85.8036 402.643 107.889C378.859 188.478 337.038 252.65 240.654 307.329L261.484 443"
                  stroke="#EB0045"
                  strokeWidth="15"
                  strokeLinejoin="round"
                />
                <path
                  d="M484.821 443L505.677 307.328C448.396 274.834 401.899 220.044 376.581 176.387"
                  stroke="#EB0045"
                  strokeWidth="15"
                  strokeLinejoin="round"
                />
                <path
                  d="M724.338 443L736.223 340.715C746.124 273.22 703.862 247.004 658.538 236.626C628.362 229.705 603.756 232.858 555.361 218.34C516.503 206.692 433.54 155.227 405.581 96.871"
                  stroke="#EB0045"
                  strokeWidth="15"
                  strokeLinejoin="round"
                />
                <path d="M92.089 356.936L101.653 443" stroke="#EB0045" strokeWidth="15" strokeLinejoin="round" />
                <path d="M650.782 356.936L641.218 443" stroke="#EB0045" strokeWidth="15" strokeLinejoin="round" />
              </g>
            </svg>
          </div>
          <div className="relative grid grid-cols-3 gap-4 lg:gap-12 items-center">
            <div className="col-span-3 lg:col-span-1 lg:-ml-8">
              {highlightedCase.featuredImage && (
                <div className="lg:w-[120%] lg:h-[120%]">
                  <LossePlaatjie
                    maxwidth={1240}
                    className="rounded-full aspect-square object-cover max-w-[75%] max-lg:max-w-[300px] lg:max-w-none mx-auto w-full h-full"
                    src={highlightedCase.featuredImage?.node}
                  />
                </div>
              )}
            </div>
            <div className="col-span-3 lg:col-span-2">
              <div className="lg:py-12">
                <Content className="text-white max-lg:children:!text-center children-headings:text-xl md:children-headings:text-2xl lg:children-headings:text-4xl children-headings:mb-4 children-h2:leading-[1.2] lg:pl-20">
                  {highlightedCase.recap?.excerpt}
                </Content>
                <div className="flex justify-center md:justify-end mt-7 max-lg:mb-8">
                  <LosseLink className="btn--white" to={highlightedCase.uri}>
                    {fields.readMoreText}
                  </LosseLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
