import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import FormContact from './FormContact'
import FormEmployeeExperience from './FormEmployeeExperience'
import FormDownload from './FormDownload'
import FormEmployeeExperienceThanks from './FormEmployeeExperienceThanks'
import FormDefault from './FormDefault'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: FormDefault,
    contact: FormContact,
    employee_experience: FormEmployeeExperience,
    employee_experience_thanks: FormEmployeeExperienceThanks,
    download: FormDownload
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
