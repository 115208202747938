import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaImage({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <section
      data-component="CtaImage"
      id={fields.sectionId ?? undefined}
      className={clsx(fields.containerWidth === 'full' ? 'bg-eo-buizel' : 'bg-transparent', 'relative mb-4 lg:mb-24')}
    >
      <div className="relative container">
        <div className="bg-eo-buizel rounded-2xl shadow-lg grid grid-cols-6 p-9 !overflow-visible">
          <div className="col-span-6 lg:col-span-1 relative !overflow-visible">
            <LossePlaatjie
              maxwidth={370}
              src={fields?.image}
              className="lg:absolute lg:-top-[42%] max-lg:h-[250px] lg:h-[370px] object-cover w-auto mx-auto max-lg:mb-5"
            />
          </div>
          <div className="col-span-6 lg:col-span-5 flex items-center">
            <Content className="text-white max-lg:children:text-center lg:px-12 children-headings:text-2xl children-headings:mb-3 children-headings:font-semibold children-p:leading-6">
              {fields.description}
            </Content>
          </div>
          {fields.link && (
            <div className="col-span-6 lg:col-span-6 flex items-center max-lg:mt-4">
              <LosseLink src={fields.link} className="btn--blue ml-auto mt-8" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
