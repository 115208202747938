import { LosseLink, LossePlaatjie, useLoaderData, useLocation } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import SearchInput from '~/components/elements/SearchInput'
import clsx from 'clsx'
import useHeader from '~/hooks/useHeader'

export default function HeaderTopmenu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { setCurrentMenuItemIndex } = useHeader()

  const { pathname } = useLocation()
  const parentPath = pathname.split('/')[1]
  const userOnEnglishLandingpage = header.topmenuHeader?.englishLandingpageLink?.url?.replace(/\//g, '') === parentPath

  return (
    <div className="justify-between items-center py-2 flex" onMouseEnter={() => setCurrentMenuItemIndex(-1)}>
      <div className="hidden md:block">
        <SearchInput />
      </div>
      <div className="flex items-center w-full justify-center md:justify-end">
        <ul className="flex items-center">
          {header.topmenuHeader?.menu?.map((edge) => {
            const partiallyCurrent = edge?.link?.url?.replace(/\//g, '') === parentPath

            return (
              <li key={edge?.link?.title} className="mr-2 xl:mr-4 max-xl:last:mr-0">
                <LosseLink
                  className={clsx(
                    partiallyCurrent ? 'bg-eo-whiscash text-white underline' : 'bg-white',
                    'inline-block text-eo-whiscash rounded-full px-3 xl:px-5 py-2 font-semibold text-sm xl:text-base hover:underline'
                  )}
                  src={edge?.link}
                />
              </li>
            )
          })}
        </ul>
        <LosseLink
          to={userOnEnglishLandingpage ? '/' : header.topmenuHeader?.englishLandingpageLink?.url}
          className="group xl:mr-4 hidden xl:block"
        >
          <label className="relative inline-flex items-center cursor-pointer">
            <div className="relative">
              <span
                className={clsx(
                  !userOnEnglishLandingpage ? 'font-bold' : 'group-hover:underline',
                  'mt-[2px] mr-1 text-sm text-eo-beautifly absolute inset-0'
                )}
              >
                NL
              </span>
              <span className="ml-1 text-sm font-bold opacity-0">{header.topmenuHeader?.englishLandingpageLink?.title}</span>
            </div>
            <div className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div
                className={clsx(
                  userOnEnglishLandingpage && 'after:translate-x-full',
                  'w-11 h-6 border border-eo-beautifly bg-white peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:border-white after:absolute after:top-[2px] after:left-[2px] after:bg-eo-whiscash after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600'
                )}
              />
            </div>
            <div className="relative">
              <span
                className={clsx(
                  userOnEnglishLandingpage ? 'font-bold' : 'group-hover:underline',
                  'mt-[2px] ml-1 text-sm text-eo-beautifly absolute inset-0'
                )}
              >
                {header.topmenuHeader?.englishLandingpageLink?.title}
              </span>
              <span className="ml-1 text-sm font-bold opacity-0">{header.topmenuHeader?.englishLandingpageLink?.title}</span>
            </div>
          </label>
        </LosseLink>
        <ul className="hidden xl:flex items-center">
          {header.topmenuHeader?.socials?.map((edge) => {
            return (
              <li key={edge?.link?.title}>
                <LosseLink to={edge?.link?.url} target={edge?.link?.target || '_blank'} className="hover:opacity-75 transition-opacity">
                  <LossePlaatjie maxwidth={20} src={edge?.icon} />
                </LosseLink>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
