import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Cta } from '~/graphql/types'

export default function CtaInBlock({ fields }: { fields: Page_Flexcontent_Flex_Cta }) {
  return (
    <>
      <section data-component="CtaInBlock" id={fields.sectionId ?? undefined} className="mt-10 lg:mt-20">
        <div className="container">
          <div className="grid grid-cols-2 items-center lg:gap-20 lg:pl-12">
            <div className={clsx(fields.reverseColumns && 'order-last', 'col-span-2 lg:col-span-1 flex flex-col items-center max-lg:mb-8')}>
              <LossePlaatjie maxwidth={750} src={fields.image} className="relative z-[2] max-h-[300px] w-auto" />
              <div className="bg-white shadow-lg rounded-3xl px-4 xl:px-20 lg:px-10 pt-32 pb-8 lg:pb-16 -mt-28 flex flex-col items-center">
                <Content className="children-headings:text-2xl children-headings:text-eo-buizel xl:children-headings:px-10 children-headings:mb-6">
                  {fields.formFields?.description}
                </Content>
                <LosseLink to={fields.formFields?.link?.url} className="btn--blue mt-7">
                  {fields.formFields?.link?.title}
                </LosseLink>
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1">
              <Content className="children-headings:!font-montserrat children-headings:text-2xl children-headings:font-semibold">
                {fields.description}
              </Content>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
