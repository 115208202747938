import Content from '~/components/elements/Content'
import type { Page, Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { useEffect, useState } from 'react'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { FormDuufGroup, FormDuufSubmit, useFormDuuf } from '@ubo/form-duuf'
import clsx from 'clsx'
import * as Yup from 'yup'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [formStatus, setFormStatus] = useState('')

  const { page } = useLoaderData<LoaderData<Page>>()

  const pageTitleField = `input_${fields.pagetitleFieldId}`

  let leftColIds = []
  let rightColIds = []

  switch (fields.form?.databaseId) {
    case 2:
      // Inspiratiesessie
      leftColIds = [10, 12, 2, 11]
      rightColIds = [3, 8, 16]
      break
    case 4:
      // Contact (english)
      leftColIds = [1, 3, 4, 5]
      rightColIds = [6, 7]
      break
    case 14:
      // Aanmelden Workshop
      leftColIds = [1, 3, 4, 5]
      rightColIds = [6, 7, 8, 9]
      break
    default:
      // Contact
      leftColIds = [10, 12, 2, 11]
      rightColIds = [3, 8, 15]
      break
  }

  return (
    <section
      data-component="FormContact"
      id={fields.sectionId ?? undefined}
      className={clsx(fields.image ? 'pt-24 md:max-lg:pt-40' : 'pt-12', 'pt-12 pb-12 lg:py-24')}
    >
      <div className="container">
        <div className="relative bg-white shadow-xl rounded-3xl p-7 lg:p-16 xl:mx-24">
          {fields.image && (
            <LossePlaatjie
              maxwidth={288}
              src={fields.image}
              className="absolute -right-4 -top-24 md:max-lg:-top-40 w-72 h-auto max-sm:w-44"
            />
          )}

          {formStatus !== 'done' && (
            <Content
              className={clsx(
                fields.image
                  ? 'children-headings:text-4xl lg:children-headings:text-8xl children-headings:text-eo-buizel'
                  : `children-headings:text-3xl lg:children-headings:text-6xl ${
                      fields.sectionId === 'contact-formulier-oplossing'
                        ? 'children-headings:text-eo-buizel'
                        : 'children-headings:text-eo-whiscash'
                    } `,
                'children-headings:mb-7 lg:children-p:text-3xl children-p:font-semibold children-p:text-eo-beautifly/70'
              )}
            >
              {fields?.title}
            </Content>
          )}

          {formStatus !== 'done' && (
            <>
              <div className="grid md:grid-cols-3 md:gap-8 mb-5 md:mb-0">
                <div className="col-span-1">
                  <Content className="mb-3 md:mb-5 children-p:leading-6">{fields?.contactinfo?.address}</Content>
                </div>
                <div className="col-span-1">
                  <p className="children-strong:text-eo-buizel flex items-center mb-0">
                    <strong>KVK</strong>
                    <span className="ml-2">{fields?.contactinfo?.kvkNumber}</span>
                  </p>
                  <p className="children-strong:text-eo-buizel flex items-center leading-4 mb-8">
                    <strong>BTW</strong>
                    <span className="ml-2">{fields?.contactinfo?.btwNumber}</span>
                  </p>
                </div>
              </div>

              <div className="grid md:grid-cols-3 md:gap-8 mb-8 lg:mb-12">
                <div className="col-span-1 mb-5 md:mb-0">
                  <p className="children-strong:text-eo-buizel mb-0 flex items-center leading-4">
                    <strong>E</strong>
                    <a href={`mailto:${fields?.contactinfo?.email}`} className="ml-2">
                      {fields?.contactinfo?.email}
                    </a>
                  </p>
                  <p className="children-strong:text-eo-buizel">
                    <strong>T</strong>
                    <a href={fields?.contactinfo?.phone?.url || '#'} className="ml-2">
                      {fields?.contactinfo?.phone?.title}
                    </a>
                  </p>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center gap-2">
                    <a href={fields?.contactinfo?.linkedinUrl || '#'} target="_blank" rel="noopener noreferrer">
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clipRule="evenodd"
                          d="M0 17.5C0 7.83649 7.83649 0 17.5 0C27.1635 0 35 7.83649 35 17.5C35 27.1635 27.1635 35 17.5 35C7.83649 35 0 27.1635 0 17.5ZM8.15266 26.4551H12.4147V13.6326H8.15266V26.4551ZM10.2838 11.8817H10.256C8.82584 11.8817 7.90085 10.8972 7.90085 9.66671C7.90085 8.40847 8.85414 7.45117 10.3121 7.45117C11.7701 7.45117 12.6673 8.40847 12.6951 9.66671C12.6951 10.8972 11.7701 11.8817 10.2838 11.8817ZM23.5212 26.4551H27.7827V19.1027C27.7827 15.1643 25.6802 13.3317 22.8761 13.3317C20.6149 13.3317 19.6015 14.5744 19.0351 15.4482V13.6326H14.7734C14.8292 14.8356 14.7734 26.4551 14.7734 26.4551H19.0351V19.2944C19.0351 18.9112 19.0627 18.5283 19.1753 18.2544C19.4835 17.4888 20.1847 16.6957 21.362 16.6957C22.9041 16.6957 23.5212 17.8714 23.5212 19.5954V26.4551Z"
                          fill="#FF8300"
                        />
                      </svg>
                    </a>
                    <a href={fields?.contactinfo?.twitterUrl || '#'} target="_blank" rel="noopener noreferrer">
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clipRule="evenodd"
                          d="M0 17.5C0 7.83504 7.83504 0 17.5 0C27.165 0 35 7.83504 35 17.5C35 27.165 27.165 35 17.5 35C7.83504 35 0 27.165 0 17.5ZM25.8382 13.844C25.8382 13.6609 25.8355 13.4777 25.8273 13.2966C26.6663 12.7004 27.392 11.9591 27.9702 11.1076C27.1852 11.4503 26.3537 11.6746 25.5029 11.7733C26.3966 11.2496 27.0685 10.4177 27.3922 9.43372C26.5469 9.92745 25.6243 10.2747 24.6632 10.4608C23.8454 9.6045 22.7119 9.12158 21.5278 9.12499C19.1559 9.12499 17.2325 11.0183 17.2325 13.354C17.2323 13.6783 17.2693 14.0016 17.3429 14.3174C13.9018 14.1539 10.6883 12.5504 8.48839 9.89922C8.1067 10.5427 7.90562 11.2773 7.90634 12.0255C7.90949 13.4458 8.62829 14.7689 9.81815 15.5446C9.13695 15.5236 8.47029 15.3423 7.87223 15.0155V15.0686C7.87223 17.1169 9.3533 18.8264 11.319 19.2157C10.6855 19.3848 10.0222 19.4095 9.37788 19.2881C9.95516 21.0207 11.565 22.1988 13.3911 22.2248C11.862 23.4028 9.98504 24.0395 8.05488 24.0349C7.71234 24.0349 7.37007 24.0153 7.02979 23.976C9.00108 25.2199 11.285 25.8785 13.616 25.8753C21.5182 25.8753 25.8382 19.4323 25.8382 13.844Z"
                          fill="#FF8300"
                        />
                      </svg>
                    </a>
                    <a href={fields?.contactinfo?.facebookUrl || '#'} target="_blank" rel="noopener noreferrer">
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clipRule="evenodd"
                          d="M0 17.4721C0 7.82944 7.83504 0.0126648 17.5 0.0126648C27.165 0.0126648 35 7.82952 35 17.4721C35 27.1146 27.165 34.9315 17.5 34.9315C7.83504 34.9315 0 27.1147 0 17.4721ZM19.0686 17.4721H22.5605L22.9897 12.7775H19.2503V10.9001C19.2503 9.98555 19.861 9.7723 20.2901 9.7723H22.9301V5.75023L19.2955 5.73561C15.2607 5.73561 14.344 8.73546 14.344 10.6556V12.7775H12.0107V17.4721H14.363V29.2084H19.0686V17.4721Z"
                          fill="#FF8300"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
          {formStatus !== 'done' && (
            <Content
              className={clsx(
                fields.image
                  ? 'children-headings:text-4xl lg:children-headings:text-8xl children-headings:text-eo-buizel'
                  : `children-headings:text-3xl lg:children-headings:text-6xl ${
                      fields.sectionId === 'contact-formulier-oplossing'
                        ? 'children-headings:text-eo-buizel'
                        : 'children-headings:text-eo-whiscash'
                    } `,
                'children-headings:mb-7 lg:children-p:text-3xl children-p:font-semibold children-p:text-eo-beautifly/70'
              )}
            >
              {fields?.description}
            </Content>
          )}

          <div className="mt-12">
            <Form
              scrollToConfirm={false}
              defaultFields={{
                [pageTitleField]: page.title ?? 'Contact'
              }}
              privacyUrl="/privacy-verklaring/"
              privacyUrlPlacement="privacy statement"
              generate={false}
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => {
                setFormStatus(status)

                return <Content className="pr-[50%]">{content}</Content>
              }}
            >
              <>
                <div className="grid grid-cols-2 lg:gap-12">
                  <div className="col-span-2 lg:col-span-1">
                    <FormDuufGroup showIds={leftColIds} />
                  </div>
                  <div className="col-span-2 lg:col-span-1">
                    <FormDuufGroup showIds={rightColIds} />
                    {fields.form?.databaseId !== 14 && (
                      <div className="justify-end flex">
                        <FormDuufSubmitCustom />
                      </div>
                    )}
                  </div>
                </div>
                {fields.form?.databaseId === 14 && (
                  <div className="justify-center flex mt-6">
                    <FormDuufSubmitCustom />
                  </div>
                )}
              </>
            </Form>
          </div>
        </div>
      </div>
    </section>
  )
}

function FormDuufSubmitCustom() {
  const duuf = useFormDuuf()

  return <FormDuufSubmit />
}
