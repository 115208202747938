import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Microcopy from '~/components/elements/Microcopy'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentTitleImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentTitleImage" id={fields.sectionId ?? undefined} className="py-10 lg:py-20">
      <div className="container">
        {fields.titlePosition === 'above_section' && (
          <Content className="children-headings:text-3xl sm:children-headings:text-4xl max-lg:children-headings:mb-4 max-lg:children-headings:!text-center lg:children-headings:text-8xl children-headings:text-eo-buizel">
            {fields.title}
          </Content>
        )}

        <div className={clsx(fields.titlePosition === 'above_section' ? 'items-end' : 'items-start', 'grid grid-cols-4 gap-4 lg:gap-12')}>
          <div
            className={clsx(
              fields.reverseColumns && 'lg:order-last',
              fields.titlePosition !== 'above_section' && 'md:col-span-1',
              'col-span-4 lg:col-span-2'
            )}
          >
            {fields.titlePosition === 'above_image' && (
              <Content className="children-headings:text-4xl max-lg:children-headings:mb-4 max-lg:children-headings:!text-center lg:children-headings:text-9xl children-headings:text-eo-ribombee lg:mb-20">
                {fields.title}
              </Content>
            )}
            <LossePlaatjie
              maxwidth={700}
              src={fields.image}
              className={clsx(
                fields.titlePosition === 'above_section' ? 'lg:max-w-full' : 'lg:ml-auto lg:-mt-24 lg:max-w-[358px]',
                'lg:mr-8 md:max-lg:max-w-[50%] max-w-[75%] max-lg:mx-auto'
              )}
            />
          </div>
          <div className={clsx(fields.titlePosition !== 'above_section' && 'md:col-span-3', 'col-span-4 lg:col-span-2')}>
            <Content
              className={clsx(
                fields.titlePosition === 'above_section'
                  ? 'children-headings:text-eo-beautifly lg:children-headings:mb-12'
                  : 'children-headings:text-eo-buizel lg:children-headings:mb-6',
                'children-headings:text-2xl lg:children-headings:text-3xl children-headings:mb-3 '
              )}
            >
              {fields.description}
            </Content>
            {fields.links?.map((edge) => {
              return (
                <div key={edge?.link?.title} className="mt-14 flex flex-col items-center lg:items-start">
                  {edge?.microcopy && <Microcopy text={edge?.microcopy} />}
                  <LosseLink className="btn--blue" key={edge?.link?.title} src={edge?.link} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
