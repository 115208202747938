import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import Logo from '~/components/elements/Logo'
import { AnimatePresence } from 'framer-motion'
import type { HeaderLoaderData } from './Header'
import useHeader from '~/hooks/useHeader'
import { motion } from 'framer-motion'
import type { Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import HeaderSubmenu from './HeaderSubmenu'
import clsx from 'clsx'

export default function HeaderSticky() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const { currentMenuItemIndex, setCurrentMenuItemIndex } = useHeader()

  if (!header.menuHeader) return null

  const currentHoveredItem = header.menuHeader[currentMenuItemIndex]

  const submenuOpen = currentMenuItemIndex !== -1 && (currentHoveredItem?.submenu || currentHoveredItem?.posts)

  return (
    <div data-component="HeaderSticky">
      <nav className="w-full relative z-[2]">
        <div className="container">
          <div className="relative">
            <div className="absolute h-full w-full top-0 left-0 bg-gradient-primary shadow-md rounded-full" />
            <div className={clsx('relative z-40 smooth flex h-full items-center justify-between rounded-full px-6 xl:px-8 py-2 xl:py-4')}>
              <LosseLink to="/" aria-label="Home">
                <Logo className="w-3/4 h-auto xl:w-auto" />
              </LosseLink>
              <HeaderDesktop />
              <HeaderMobile />
            </div>
            <AnimatePresence>
              {(currentHoveredItem?.submenu || currentHoveredItem?.posts) && (
                <motion.div
                  key={`submenu_${currentMenuItemIndex}`}
                  onMouseLeave={() => setCurrentMenuItemIndex(-1)}
                  className="absolute left-0 z-[100] xl:z-20 w-full top-0 rounded-[2.75rem]"
                >
                  <div
                    className={clsx(
                      submenuOpen ? 'opacity-100' : 'opacity-0',
                      'absolute z-30 xl:block hidden smooth bg-gradient-primary w-full h-full rounded-[2.75rem] top-0 left-0'
                    )}
                  />
                  <HeaderSubmenu
                    parentUrl={currentHoveredItem.link?.url}
                    submenu={currentHoveredItem?.submenu as Component_Header_MenuHeader_Submenu[]}
                    posts={currentHoveredItem?.posts?.edges}
                    showPosts={currentHoveredItem?.showPosttype || false}
                    showResume={currentHoveredItem?.showResume || false}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </nav>
    </div>
  )
}
