import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerLanding" id={fields.sectionId ?? undefined} className="pb-12 lg:pb-24">
      <div className="container">
        <div className="grid grid-cols-2 items-center lg:gap-x-8 xl:gap-x-16">
          <div className="col-span-2 lg:col-span-1 lg:-mr-32">
            <LossePlaatjie maxwidth={700} loading="eager" src={fields.image} className="w-auto md:max-h-[350px] mx-auto lg:max-h-[700px]" />
          </div>
          <div className="col-span-2 lg:col-span-1 lg:order-first">
            <Content className="pb-6 lg:pb-12 pt-4 lg:pt-20 children-p:pr-4 lg:children-p:pr-16 children-headings:mb-4 lg:children-headings:mb-10 children-headings:text-2xl md:children-headings:text-4xl xl:children-headings:text-5xl children-headings:leading-[1.2] children-headings:text-eo-buizel">
              {fields.description}
            </Content>
            {fields.links && (
              <div className="flex flex-col md:max-lg:flex-row flex-wrap lg:items-start items-center">
                {fields.links.map((edge, index) => {
                  const odd = index % 2 === 0

                  return (
                    <div key={edge?.link?.title} className="mb-4 lg:mb-6 md:max-lg:mr-4">
                      <LosseLink src={edge?.link} className={clsx(odd ? 'btn--blue' : 'btn--blue--outline', 'min-w-[300px]')} />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
