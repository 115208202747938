import { LossePlaatjie, useLoaderData, useLosseZoekenMoek } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Searchresults } from '~/graphql/types'
import { type LoaderData } from '@ubo/losse-sjedel'
import { Form, LosseLink } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import type { Page, Post } from '~/graphql/types'

export default function Searchresults({ fields }: { fields: Page_Flexcontent_Flex_Searchresults }) {
  const data = useLoaderData<LoaderData>()
  const [state, result, currentValue, next, prev] = useLosseZoekenMoek()

  const contentTypeNames: {
    [key: string]: string
  } = {
    solution: 'Oplossing',
    service: 'Dienst',
    case: 'Case',
    page: 'Pagina',
    post: 'Kennis'
  }

  return (
    <section data-component="Searchresults" id={fields.sectionId ?? undefined} className="section">
      <div className="container relative pb-20 xl:max-w-screen-xl">
        <div className="flex flex-col items-center">
          <img loading="eager" src="/images/eo-mascot-searching.png" alt="eo-mascot-searching" className="h-[300px] w-auto mx-auto" />
          <Form
            action={new URL(data.request.url).pathname}
            method="get"
            className="relative z-10 flex items-center overflow-hidden rounded-full shadow-lg min-w-[272px] "
          >
            <input
              type="search"
              name="q"
              placeholder="Typ hier om te zoeken"
              defaultValue={currentValue}
              className="md:w-72 rounded-none py-2 pl-6 pr-3"
            />

            <button type="submit" className="bg-eo-ledian block h-full text-white font-bold rounded-l-none py-2 px-4 md:px-6">
              <span className="hidden md:block">Zoeken</span>
              <svg className="md:hidden" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5" clipPath="url(#clip0_38_608)">
                  <path
                    d="M19.7557 18.5781L14.7815 13.6039C16.137 11.946 16.8035 9.83063 16.643 7.69519C16.4826 5.55974 15.5075 3.56766 13.9195 2.13098C12.3315 0.69431 10.252 -0.0770391 8.11119 -0.0235126C5.9704 0.030014 3.93207 0.90432 2.41783 2.41856C0.903588 3.9328 0.0292815 5.97113 -0.024245 8.11192C-0.0777715 10.2527 0.693577 12.3322 2.13025 13.9202C3.56693 15.5082 5.55901 16.4833 7.69445 16.6438C9.8299 16.8042 11.9453 16.1377 13.6032 14.7822L18.5773 19.7564C18.7345 19.9082 18.945 19.9922 19.1635 19.9903C19.382 19.9884 19.591 19.9007 19.7455 19.7462C19.9 19.5917 19.9877 19.3827 19.9895 19.1642C19.9914 18.9457 19.9075 18.7352 19.7557 18.5781ZM8.33315 15.0006C7.01461 15.0006 5.72568 14.6096 4.62935 13.877C3.53302 13.1445 2.67854 12.1033 2.17395 10.8851C1.66937 9.66693 1.53735 8.32649 1.79458 7.03328C2.05182 5.74008 2.68676 4.55219 3.61911 3.61984C4.55146 2.68749 5.73934 2.05255 7.03255 1.79532C8.32576 1.53808 9.6662 1.6701 10.8844 2.17469C12.1025 2.67927 13.1437 3.53375 13.8763 4.63008C14.6088 5.72641 14.9998 7.01534 14.9998 8.33389C14.9978 10.1014 14.2948 11.7959 13.045 13.0457C11.7952 14.2956 10.1007 14.9986 8.33315 15.0006Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_38_608">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </Form>
        </div>

        {(state === 'loading' || state === 'submitting') && !!currentValue && (
          <div className="flex justify-center py-10">
            <Loading />
          </div>
        )}

        <div className="mt-10 flex justify-center">
          {state === 'idle' && (
            <div className="w-4/5">
              {result?.edges?.length === 0 && <Content className="content">Geen resultaten</Content>}
              <div className="grid grid-cols-1 xs:grid-cols-2 gap-4 sm:gap-6 lg:grid-cols-3 lg:gap-10">
                {result?.edges?.map((edge) => {
                  if (!edge) return null
                  if (!edge.node) return null
                  const node = edge.node as Post | Page

                  return (
                    <div key={node.id} className="col-span-1 flex flex-col p-8 shadow-lg bg-white">
                      <div className="flex items-center mb-14">
                        {node.featuredImage?.node && (
                          <LossePlaatjie
                            maxwidth={150}
                            src={node.featuredImage?.node}
                            className="w-24 h-24 object-contain rounded-full mr-4"
                          />
                        )}
                        <h3 className="text-xl">{node.title}</h3>
                      </div>
                      <div className="flex justify-between items-center mt-auto">
                        <div className="rounded-full bg-eo-buizel px-3 py-1 text-xs font-bold uppercase text-white">
                          {contentTypeNames[node.contentTypeName] || node.contentTypeName}
                        </div>
                        <div className="flex justify-end">
                          <LosseLink to={node.uri} className="btn--blue--transparent">
                            Bekijken
                          </LosseLink>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>

        <div className="mt-10 flex justify-end">
          <button
            type="button"
            className="ml-3 flex h-8 w-8 items-center justify-center rounded-full disabled:opacity-25 lg:h-12 lg:w-12"
            disabled={!result?.pageInfo?.hasPreviousPage}
            onClick={() => {
              prev()
            }}
          >
            <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
              <path
                d="M1.66694e-06 5.5C-0.000374548 5.28465 0.041951 5.07135 0.124546 4.87236C0.207141 4.67336 0.328377 4.4926 0.481285 4.34046L4.83669 -5.08498e-08L6 1.15953L1.64541 5.5L6 9.84046L4.83669 11L0.482108 6.65953C0.329051 6.50746 0.207664 6.32673 0.124926 6.12774C0.0421889 5.92874 -0.000266325 5.71541 1.66694e-06 5.5Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            type="button"
            className="ml-3 flex h-8 w-8 items-center justify-center rounded-full disabled:opacity-25 lg:h-12 lg:w-12"
            disabled={!result?.pageInfo?.hasNextPage}
            onClick={() => {
              next()
            }}
          >
            <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
              <path
                d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  )
}
