import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function BannerCta({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerCta" id={fields.sectionId ?? undefined} className="py-10 lg:py-20">
      <div className="container">
        <div className="grid grid-cols-2 gap-12 lg:gap-24 items-center">
          <div className="col-span-2 lg:col-span-1">
            <Content className="children-h1:text-4xl lg:children-h1:text-7xl children-h1:mb-3 lg:children-h1:mb-9 children-h1:text-eo-buizel children-h2:text-xl lg:children-h2:text-3xl children-h2:text-eo-stakataka">
              {fields.description}
            </Content>
            {fields.links && (
              <div className="grid grid-cols-3 gap-4 mt-7 lg:mt-14">
                {fields.links.map((edge) => {
                  return (
                    <div key={edge?.link?.title} className="col-span-3 md:col-span-1">
                      <LosseLink src={edge?.link} className="btn--white--blue w-full" />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1 justify-self-center">
            <div className="relative flex items-end justify-center">
              <LossePlaatjie maxwidth={500} src={fields.image} className="w-auto" />
              <div className="absolute -bottom-4">
                <LosseLink className="btn--blue" src={fields.cta} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
