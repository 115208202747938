import type { PropsWithChildren } from 'react'
import { useEffect, useState, createContext } from 'react'
import type { LoaderData } from '@ubo/losse-sjedel'
import { Link, LosseLayout, LossePlaatjie, useLoaderData, useLocation, useNavigate } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'
import Fingerprint from '../elements/Fingerprint'
import Modal from '../elements/Modal'
import type { Component_Contactpopup, Component_Popupimagelink, Page } from '~/graphql/types'
import Form from '../elements/Form'
import Loading from '../elements/Loading'
import Content from '../elements/Content'

export interface ContactPopupLoaderData extends Omit<LoaderData, 'contactPopup'> {
  rest: {
    component: {
      contactPopup: Component_Contactpopup
    }
    popup: {
      popupImageLink: Component_Popupimagelink
    }
  }
}

export const CustomLayoutContext = createContext<{
  sticky: boolean
}>({
  sticky: false
})

export default function Layout({ children }: PropsWithChildren<{}>) {
  const { page } = useLoaderData<LoaderData<Page>>()
  const {
    rest: {
      component: { contactPopup },
      popup: { popupImageLink }
    }
  } = useLoaderData<ContactPopupLoaderData>()
  const [, setFormStatus] = useState('')
  const [sticky, setSticky] = useState(false)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [imageLinkPopupOpen, setImageLinkPopupOpen] = useState(false)
  const [, setIsSlideOpen] = useState(false)
  const { hash, pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    function handleScroll() {
      setSticky(window.scrollY > 300)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  useEffect(() => {
    const hasSeenPopupImage = window.localStorage.getItem('eoffice-popup-image') || false

    if (!hasSeenPopupImage || hasSeenPopupImage === 'false') {
      setImageLinkPopupOpen(true)
      window.localStorage.setItem('eoffice-popup-image', 'true')
    }
  }, [])

  useEffect(() => {
    if (hash === '#contact-popup') {
      setContactModalOpen(true)
    }
  }, [hash])

  useEffect(() => {
    // if hash ==== #doedetest, wait scrolling for 1 second an then scroll to the element
    if (hash === '#doedetest') {
      setTimeout(() => {
        const element = document.getElementById('doedetest')
        element?.scrollIntoView({ behavior: 'smooth' })
      }, 1000)
    }
  }, [hash])

  function closeModal() {
    setContactModalOpen(false)
    navigate(pathname)
  }

  useEffect(() => {
    setTimeout(() => {
      !sessionStorage.getItem('hide') && setIsSlideOpen(true)
    }, 4000)
  }, [])

  // function closeSlideModal() {
  //   sessionStorage.setItem('hide', 'true')
  //   setIsSlideOpen(false)
  // }

  return (
    <LosseLayout>
      <CustomLayoutContext.Provider value={{ sticky }}>
        <div className="relative w-full h-full max-w-screen-2xl mx-auto">
          <div className="absolute top-[30vh] -left-24">
            <Fingerprint />
          </div>
        </div>

        <Header />
        <main className="relative min-h-screen overflow-hidden">
          <>{children}</>
        </main>
        <Footer />
        <Modal isOpen={imageLinkPopupOpen} requestClose={() => setImageLinkPopupOpen(false)} bgColor="#FFF">
          <div onClick={() => setImageLinkPopupOpen(false)} className="relative">
            <Link to={popupImageLink?.page?.uri || '/'}>
              <LossePlaatjie src={popupImageLink?.image} alt="popup" />
            </Link>
          </div>
        </Modal>
        <Modal isOpen={contactModalOpen} requestClose={() => closeModal()} bgColor="#FFF">
          <div className="relative">
            <img
              src="/images/eo-mascot-cloud.png"
              alt=""
              className="absolute right-6 lg:right-2 -top-6 lg:-top-20 xl:-top-14 w-36 lg:w-48 xl:w-60"
            />
            <Content className="text-white w-4/5 lg:w-full children-headings:text-2xl lg:children-headings:text-4xl children-headings:text-eo-buizel lg:children-p:text-xl mb-12">
              {contactPopup.description}
            </Content>
            <Form
              className="form-popup"
              scrollToConfirm={false}
              defaultFields={{
                input_14: page.title ?? 'Contact'
              }}
              data={contactPopup.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => {
                setFormStatus(status)

                return <Content>{content}</Content>
              }}
            />
          </div>
        </Modal>
        {/* {page?.title === 'Home' && (
          <SlideIn isOpen={isSlideOpen} requestClose={() => closeSlideModal()} bgColor="#FFF">
            <div>
              <a href="/kennis-die-we-delen/webinar-windows-365-cloud-pc/"><img src="/images/EO-CloudPC-Pop_up.png" alt="EO" /></a>
            </div>
          </SlideIn>
        )} */}
      </CustomLayoutContext.Provider>
    </LosseLayout>
  )
}
