import { compose, withProps } from 'recompose'

import settings from './settings.json'

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import type { Page_Flexcontent_Flex_Googlemaps } from '~/graphql/types'
import Content from '~/components/elements/Content'

const GoogleMapsComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     */
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDwiS-BFz1vkdzv0w13546jbx8pY-zpXFU&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(() => (
  // @ts-ignore
  <GoogleMap
    options={{
      styles: settings,
      fullscreenControl: false,
      controlSize: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false
    }}
    defaultZoom={14}
    defaultCenter={{ lat: 52.023888, lng: 5.142992 }}
    yesIWantToUseGoogleMapApiInternals
  >
    <Marker defaultAnimation="drop" icon="/static/svg/eo-map-pin.svg" position={{ lat: 52.023888, lng: 5.142992 }} />
  </GoogleMap>
))

const GoogleMaps = ({ fields }: { fields: Page_Flexcontent_Flex_Googlemaps }) => {
  return (
    <section data-component="GoogleMaps" id={fields.sectionId ?? undefined} className="max-w-screen-2xl mx-auto !mb-0">
      <div className="container">
        <Content className="children-headings:text-6xl children-headings:text-eo-buizel children-headings:mb-7">
          {fields.description}
        </Content>
      </div>
      <div className="relative">
        <iframe title="Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4910.1520405974625!2d5.140460977661964!3d52.02371277285327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c66691818993e9%3A0x5763312f9ae43340!2se-office%20%7C%20Put%20people%20first!5e0!3m2!1snl!2snl!4v1712903091279!5m2!1snl!2snl" width="100%" height="450" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
      </div>
    </section>
  )
}

export default GoogleMaps
