import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import CasesHighlighted from './CasesHighlighted'
import CasesOverview from './CasesOverview'

export default function CasesShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    highlighted: CasesHighlighted,
    overview: CasesOverview
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
