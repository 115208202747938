import { useLocation } from '@ubo/losse-sjedel'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share'

export default function Share() {
  const baseUrl = 'https://www.e-office.com'
  const loc = useLocation()
  const shareUrl = baseUrl + loc.pathname

  return (
    <div className="flex flex-col max-lg:items-center">
      <span className="inline-block mb-2 text-lg max-lg:text-center font-semibold">Dit item delen:</span>
      <div className="flex [&_rect]:fill-eo-buizel [&_svg]:rounded-full [&_svg]:w-11 [&_svg]:h-11 max-lg:[&_svg]:mx-1 lg:[&_svg]:mr-2">
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon />
        </LinkedinShareButton>
        <EmailShareButton url={shareUrl}>
          <EmailIcon />
        </EmailShareButton>
      </div>
    </div>
  )
}
