import Content from '~/components/elements/Content'
import type { Page, Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { useEffect, useState } from 'react'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { FormDuufGroup, FormDuufSubmit, useFormDuuf } from '@ubo/form-duuf'
import clsx from 'clsx'
import * as Yup from 'yup'

export default function FormDefault({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [formStatus, setFormStatus] = useState('')

  const { page } = useLoaderData<LoaderData<Page>>()

  const pageTitleField = `input_${fields.pagetitleFieldId}`

  let leftColIds = []
  let rightColIds = []

  switch (fields.form?.databaseId) {
    case 2:
      // Inspiratiesessie
      leftColIds = [10, 12, 2, 11]
      rightColIds = [3, 8, 16]
      break
    case 4:
      // Contact (english)
      leftColIds = [1, 3, 4, 5]
      rightColIds = [6, 7]
      break
    case 14:
      // Aanmelden Workshop
      leftColIds = [1, 3, 4, 5]
      rightColIds = [6, 7, 8, 9]
      break
    default:
      // Contact
      leftColIds = [10, 12, 2, 11]
      rightColIds = [3, 8, 15]
      break
  }

  return (
    <section
      data-component="FormDefault"
      id={fields.sectionId ?? undefined}
      className="py-12"
    >
      <div className="container">
        <div className="relative bg-white shadow-xl rounded-3xl p-7 lg:p-16 xl:mx-24">
          {fields.image && (
            <LossePlaatjie
              maxwidth={288}
              src={fields.image}
              className="absolute -right-4 -top-24 md:max-lg:-top-40 w-72 h-auto max-sm:w-44"
            />
          )}

          {formStatus !== 'done' && (
            <Content
              className={clsx(
                fields.image
                  ? 'children-headings:text-4xl lg:children-headings:text-8xl children-headings:text-eo-buizel'
                  : `children-headings:text-3xl lg:children-headings:text-6xl ${fields.sectionId === 'contact-formulier-oplossing'
                    ? 'children-headings:text-eo-buizel'
                    : 'children-headings:text-eo-whiscash'
                  } `,
                'children-headings:mb-7 lg:children-p:text-3xl children-p:font-semibold children-p:text-eo-beautifly/70'
              )}
            >
              {fields?.title}
            </Content>
          )}

          {formStatus !== 'done' && (
            <Content
              className={clsx(
                fields.image
                  ? 'children-headings:text-4xl lg:children-headings:text-8xl children-headings:text-eo-buizel'
                  : `children-headings:text-3xl lg:children-headings:text-6xl ${fields.sectionId === 'contact-formulier-oplossing'
                    ? 'children-headings:text-eo-buizel'
                    : 'children-headings:text-eo-whiscash'
                  } `,
                'children-headings:mb-7 lg:children-p:text-3xl children-p:font-semibold children-p:text-eo-beautifly/70'
              )}
            >
              {fields?.description}
            </Content>
          )}

          <div className="mt-12">
            <Form
              scrollToConfirm={false}
              defaultFields={{
                [pageTitleField]: page.title ?? 'Contact'
              }}
              privacyUrl="/privacy-verklaring/"
              privacyUrlPlacement="privacy statement"
              data={fields.form}
              renderLoading={() => <Loading />}
              renderStatus={({ status, content }) => {
                setFormStatus(status)

                return <Content className="pr-[50%]">{content}</Content>
              }}
            />

          </div>
        </div>
      </div>
    </section>
  )
}

function FormDuufSubmitCustom() {
  const duuf = useFormDuuf()

  return <FormDuufSubmit />
}
