export default function LogoDark({ className }: { className?: string }) {
  return (
    <svg width="200" height="52" viewBox="0 0 200 52" fill="none" xmlns="http://www.w3.org/2000/svg" className={className ?? undefined}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.0951 34.9002C94.5623 36.1622 93.825 37.2697 92.8834 38.2216C91.9408 39.1735 90.8455 39.9193 89.5965 40.4571C88.3474 40.9949 87.0058 41.2634 85.5731 41.2634C84.139 41.2634 82.7983 40.9949 81.5493 40.4571C80.2993 39.9193 79.2039 39.1735 78.2628 38.2216C77.3198 37.2697 76.5731 36.1622 76.0203 34.9002C75.467 33.6376 75.1908 32.2819 75.1908 30.8324C75.1908 29.3839 75.467 28.0287 76.0203 26.7661C76.5731 25.5041 77.3198 24.3966 78.2628 23.4447C79.2039 22.4932 80.2993 21.747 81.5493 21.2092C82.7983 20.6714 84.139 20.4015 85.5731 20.4015C87.0058 20.4015 88.3474 20.6714 89.5965 21.2092C90.8455 21.747 91.9408 22.4932 92.8834 23.4447C93.825 24.3966 94.5623 25.5041 95.0951 26.7661C95.6268 28.0287 95.8937 29.3839 95.8937 30.8324C95.8937 32.2819 95.6268 33.6376 95.0951 34.9002M96.3852 19.9598C94.9507 18.5183 93.303 17.4061 91.4399 16.6233C89.5754 15.841 87.6203 15.4493 85.5731 15.4493C83.4838 15.4493 81.5184 15.841 79.6754 16.6233C77.8329 17.4061 76.1941 18.5183 74.7609 19.9598C73.3268 21.4008 72.2212 23.0493 71.4437 24.9026C70.6652 26.7558 70.2764 28.7328 70.2764 30.8324C70.2764 32.8928 70.6652 34.8594 71.4437 36.7328C72.2212 38.6076 73.3268 40.2646 74.7609 41.7056C76.1941 43.1475 77.8329 44.2602 79.6754 45.0425C81.5184 45.8253 83.4838 46.216 85.5731 46.216C87.6203 46.216 89.5754 45.8253 91.4399 45.0425C93.303 44.2602 94.9507 43.1475 96.3852 41.7056C97.818 40.2646 98.9133 38.6076 99.6717 36.7328C100.429 34.8594 100.809 32.8928 100.809 30.8324C100.809 28.7328 100.429 26.7558 99.6717 24.9026C98.9133 23.0493 97.818 21.4008 96.3852 19.9598"
        fill="#676767"
      />
      <mask id="mask0_420_904" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="102" y="0" width="16" height="46">
        <path fillRule="evenodd" clipRule="evenodd" d="M102.773 0.468475H117.886V45.8446H102.773V0.468475Z" fill="white" />
      </mask>
      <g mask="url(#mask0_420_904)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.688 12.8494C107.729 11.8183 107.965 10.8481 108.395 9.93979C108.825 9.03283 109.387 8.24815 110.084 7.58761C110.78 6.92754 111.589 6.40145 112.51 6.00887C113.432 5.61723 114.404 5.42094 115.429 5.42094C116.125 5.42094 116.708 5.1839 117.18 4.70887C117.65 4.23478 117.886 3.64685 117.886 2.94462C117.886 2.24332 117.65 1.65492 117.18 1.18036C116.708 0.706274 116.125 0.468292 115.429 0.468292C113.667 0.468292 112.02 0.799031 110.483 1.4591C108.948 2.11964 107.606 3.028 106.459 4.18278C105.313 5.33849 104.411 6.69002 103.757 8.23737C103.101 9.78519 102.773 11.4469 102.773 13.2205V13.5306V43.3683C102.773 44.0705 103.008 44.6589 103.48 45.133C103.951 45.6076 104.534 45.8446 105.231 45.8446C105.927 45.8446 106.51 45.6076 106.982 45.133C107.452 44.6589 107.688 44.0705 107.688 43.3683V21.2683H115.429C116.125 21.2683 116.708 21.0312 117.18 20.5567C117.65 20.0826 117.886 19.4947 117.886 18.7924C117.886 18.0907 117.65 17.5027 117.18 17.0277C116.708 16.5541 116.125 16.3161 115.429 16.3161H107.688V13.035V12.8494Z"
          fill="#676767"
        />
      </g>
      <mask id="mask1_420_904" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="119" y="0" width="16" height="46">
        <path fillRule="evenodd" clipRule="evenodd" d="M119.852 0.468475H134.964V45.8446H119.852V0.468475Z" fill="white" />
      </mask>
      <g mask="url(#mask1_420_904)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.766 12.8494C124.807 11.8183 125.043 10.8481 125.473 9.93979C125.903 9.03283 126.466 8.24815 127.162 7.58761C127.858 6.92754 128.667 6.40145 129.588 6.00887C130.51 5.61723 131.482 5.42094 132.506 5.42094C133.203 5.42094 133.786 5.1839 134.258 4.70887C134.728 4.23478 134.964 3.64685 134.964 2.94462C134.964 2.24332 134.728 1.65492 134.258 1.18036C133.786 0.706274 133.203 0.468292 132.506 0.468292C130.745 0.468292 129.097 0.799031 127.561 1.4591C126.025 2.11964 124.684 3.028 123.538 4.18278C122.39 5.33849 121.489 6.69002 120.835 8.23737C120.179 9.78519 119.852 11.4469 119.852 13.2205V13.5306V43.3683C119.852 44.0705 120.087 44.6589 120.558 45.133C121.029 45.6076 121.612 45.8446 122.309 45.8446C123.005 45.8446 123.588 45.6076 124.06 45.133C124.53 44.6589 124.766 44.0705 124.766 43.3683V21.2683H132.506C133.203 21.2683 133.786 21.0312 134.258 20.5567C134.728 20.0826 134.964 19.4947 134.964 18.7924C134.964 18.0907 134.728 17.5027 134.258 17.0277C133.786 16.5541 133.203 16.3161 132.506 16.3161H124.766V13.035V12.8494Z"
          fill="#676767"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.572 7.09216C138.835 7.09216 138.21 7.35357 137.698 7.87544C137.186 8.39825 136.931 9.03443 136.931 9.78539C136.931 10.5368 137.186 11.173 137.698 11.6953C138.21 12.2177 138.835 12.4781 139.572 12.4781C140.309 12.4781 140.933 12.2177 141.446 11.6953C141.957 11.173 142.213 10.5368 142.213 9.78539C142.213 9.03443 141.957 8.39825 141.446 7.87544C140.933 7.35357 140.309 7.09216 139.572 7.09216"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.51 15.5731C138.813 15.5731 138.229 15.811 137.759 16.2851C137.288 16.7602 137.053 17.3481 137.053 18.0494V42.8112C137.053 43.5135 137.288 44.1014 137.759 44.5755C138.229 45.0505 138.813 45.2875 139.51 45.2875C140.206 45.2875 140.789 45.0505 141.261 44.5755C141.731 44.1014 141.967 43.5135 141.967 42.8112V18.0494C141.967 17.3481 141.731 16.7602 141.261 16.2851C140.789 15.811 140.206 15.5731 139.51 15.5731"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.721 37.2706C168.046 37.2922 167.483 37.5494 167.032 38.0445C166.049 39.0766 164.922 39.8706 163.653 40.4281C162.384 40.9851 161.093 41.2849 159.783 41.3257C158.472 41.3669 157.172 41.1505 155.882 40.6754C154.592 40.2014 153.434 39.4682 152.411 38.4779C150.322 36.4972 149.237 34.0827 149.156 31.2349C149.032 28.3468 149.954 25.8494 151.92 23.7445C152.862 22.7134 153.956 21.9287 155.206 21.3919C156.455 20.8564 157.776 20.5468 159.169 20.4638C161.994 20.3814 164.452 21.3305 166.541 23.3112C167.032 23.7656 167.625 23.9825 168.322 23.961C169.018 23.9408 169.591 23.6827 170.042 23.1875C170.492 22.6923 170.707 22.0941 170.687 21.3919C170.666 20.691 170.411 20.1134 169.919 19.659C168.445 18.2564 166.765 17.1935 164.882 16.4706C162.998 15.7492 161.032 15.4287 158.984 15.5112C156.936 15.5941 155.001 16.0481 153.179 16.873C151.356 17.6994 149.748 18.8542 148.357 20.3397C146.923 21.8674 145.858 23.5693 145.162 25.4469C144.465 27.3255 144.158 29.2959 144.241 31.359C144.322 33.4638 144.772 35.4244 145.592 37.2402C146.411 39.0564 147.558 40.6661 149.032 42.0682V42.1305C150.466 43.4516 152.073 44.4733 153.855 45.1948C155.636 45.9171 157.51 46.2779 159.476 46.2779H159.968C162.014 46.1959 163.95 45.7419 165.772 44.916C167.595 44.091 169.202 42.9147 170.595 41.3875C171.045 40.8923 171.261 40.3039 171.241 39.6233C171.219 38.9421 170.964 38.3753 170.472 37.9208C169.981 37.4669 169.397 37.2505 168.721 37.2706"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.748 36.0018C179.174 35.0531 178.765 34.0421 178.519 32.9684C178.273 31.8956 178.201 30.8228 178.304 29.7491C178.406 28.6768 178.683 27.6241 179.134 26.5921C179.584 25.561 180.218 24.6325 181.038 23.8066C182.021 22.7755 183.137 22.0016 184.386 21.4849C185.635 20.9695 186.915 20.7109 188.226 20.7109C189.904 20.7109 191.522 21.1447 193.079 22.0109L179.748 36.0018ZM198.915 37.5655C198.423 37.113 197.829 36.8975 197.133 36.9176C196.437 36.9387 195.843 37.1963 195.352 37.6896C194.532 38.5137 193.632 39.1733 192.649 39.6666C191.666 40.1608 190.641 40.4906 189.577 40.6551C188.512 40.8195 187.447 40.7993 186.382 40.5932C185.317 40.388 184.314 40.0165 183.373 39.4811L198.608 23.4815C199.058 22.9455 199.284 22.3689 199.284 21.7509C199.284 21.134 199.058 20.5573 198.608 20.0214L198.546 19.9595C197.112 18.5602 195.474 17.4996 193.632 16.7781C191.789 16.0576 189.884 15.7185 187.918 15.7588C185.911 15.8421 184.007 16.2745 182.205 17.0569C180.402 17.8392 178.826 18.9509 177.475 20.3919C176.082 21.8343 175.027 23.4716 174.311 25.3038C173.594 27.1369 173.256 29.0619 173.297 31.0791C173.338 33.0982 173.759 35.0025 174.557 36.7939C175.356 38.5854 176.471 40.181 177.905 41.5817C179.297 42.9416 180.874 43.9816 182.635 44.7022C184.395 45.4227 186.26 45.7824 188.226 45.7824C188.266 45.7824 188.307 45.7731 188.348 45.7515C188.389 45.7314 188.45 45.7206 188.533 45.7206C190.539 45.6803 192.433 45.2681 194.216 44.4857C195.996 43.7029 197.584 42.5908 198.976 41.1493C199.426 40.6551 199.652 40.0582 199.652 39.3583C199.652 38.6575 199.406 38.0611 198.915 37.5655V37.5655Z"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.8787 27.9541H50.5922C49.8955 27.9541 49.3119 28.1911 48.8413 28.6661C48.3703 29.1398 48.1348 29.7282 48.1348 30.4304C48.1348 31.1317 48.3703 31.7196 48.8413 32.1946C49.3119 32.6683 49.8955 32.9063 50.5922 32.9063H62.8787C63.5745 32.9063 64.1581 32.6683 64.6296 32.1946C65.0997 31.7196 65.3362 31.1317 65.3362 30.4304C65.3362 29.7282 65.0997 29.1398 64.6296 28.6661C64.1581 28.1911 63.5745 27.9541 62.8787 27.9541"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.91179 16.617C5.11506 16.617 5.31553 16.5229 5.44497 16.3453C8.60338 12.004 13.2375 9.02739 18.4945 7.96397C23.1595 7.02047 27.8767 7.63088 32.1366 9.72822C32.4637 9.88937 32.8585 9.75398 33.0188 9.42606C33.1791 9.09813 33.0445 8.7018 32.7174 8.54112C28.194 6.31402 23.1861 5.66613 18.2342 6.66771C12.6529 7.79625 7.73282 10.9565 4.37955 15.5658C4.16506 15.8609 4.22955 16.2746 4.52394 16.4901C4.64076 16.5758 4.77674 16.617 4.91179 16.617"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9597 24.3672C43.7807 18.6701 40.5228 13.6973 35.7732 10.3539C35.4746 10.144 35.0639 10.2166 34.855 10.5155C34.6461 10.8144 34.7176 11.2271 35.0157 11.4365C39.5237 14.6099 42.6064 19.3419 43.6957 24.7602C43.7036 24.7996 43.7148 24.8375 43.7288 24.874C44.0232 26.3797 44.1587 27.9135 44.1298 29.435C44.1228 29.8 44.412 30.1017 44.776 30.1087C44.7802 30.1087 44.7849 30.1087 44.7896 30.1087C45.1471 30.1087 45.441 29.8211 45.448 29.4603C45.4798 27.8034 45.3288 26.1337 44.9999 24.4965C44.9905 24.4515 44.977 24.4079 44.9597 24.3672"
        fill="#676767"
      />
      <mask id="mask2_420_904" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="18" width="4" height="17">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 18.2694H3.74547V34.9782H0V18.2694Z" fill="white" />
      </mask>
      <g mask="url(#mask2_420_904)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.96712 34.1579C1.89328 33.8571 1.82413 33.5493 1.76291 33.242C1.7545 33.2027 1.74328 33.1657 1.7288 33.1296C0.795154 28.3573 1.46618 23.5546 3.67272 19.2325C3.83908 18.9074 3.71057 18.5087 3.38674 18.3424C3.06291 18.1756 2.66525 18.3044 2.49936 18.6296C0.137677 23.2557 -0.568397 28.3995 0.458238 33.5058C0.467117 33.5493 0.480201 33.5906 0.497023 33.6299C0.555434 33.9138 0.618518 34.1972 0.687677 34.4755C0.761509 34.7767 1.03067 34.9782 1.32693 34.9782C1.37927 34.9782 1.43207 34.9721 1.48581 34.9585C1.83908 34.8709 2.0545 34.5125 1.96712 34.1579"
          fill="#676767"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5984 28.353C22.2414 28.4252 22.0105 28.7737 22.0825 29.1321C22.447 30.9451 22.6026 32.7646 22.56 34.5621L23.8736 33.1904C23.8287 31.7551 23.6647 30.3112 23.375 28.8702C23.3035 28.5123 22.9549 28.28 22.5984 28.353"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8856 42.8333C19.8729 45.392 18.4136 47.7953 16.5342 49.9413C16.294 50.2154 16.3206 50.6333 16.594 50.8745C16.7197 50.9851 16.8748 51.0389 17.0295 51.0389C17.2127 51.0389 17.3944 50.9631 17.5253 50.8141C19.4407 48.6263 20.9407 46.1847 22.0033 43.5865C21.6201 43.3533 21.2472 43.1026 20.8856 42.8333"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5838 41.7432C19.5095 41.6739 19.4338 41.6069 19.3599 41.5362C19.2885 41.4664 19.2212 41.3942 19.1511 41.3235C18.8254 41.2247 18.4735 41.3896 18.3473 41.7133C17.2936 44.4224 15.7324 46.8528 13.7067 48.9361C13.4525 49.198 13.4581 49.6163 13.7184 49.8716C13.8469 49.9967 14.0128 50.059 14.1786 50.059C14.3501 50.059 14.5216 49.9925 14.6506 49.8595C16.8001 47.6483 18.4576 45.0694 19.5758 42.1939C19.6342 42.0435 19.6314 41.8852 19.5838 41.7432"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9798 39.997C26.1359 39.285 26.2639 38.5673 26.3649 37.8444L24.835 39.4428C25.2069 39.6536 25.5887 39.8378 25.9798 39.997"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0971 44.6447C23.8139 44.5294 23.5373 44.4029 23.2635 44.2713C22.3401 46.5017 21.1135 48.621 19.5981 50.5666C19.3738 50.8542 19.4242 51.2697 19.7111 51.4951C19.8317 51.5892 19.9752 51.6352 20.1167 51.6352C20.3125 51.6352 20.5065 51.548 20.6368 51.3808C22.2238 49.3434 23.5074 47.1238 24.4756 44.788C24.349 44.7407 24.2224 44.6957 24.0971 44.6447"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1881 28.9057L26.2021 28.901L26.0858 28.321C25.712 26.4607 23.8993 25.2526 22.0447 25.6278C21.5877 25.7206 21.1708 25.9014 20.8059 26.1483C20.7302 26.2977 20.6573 26.4481 20.59 26.6022C20.1381 27.631 19.861 28.6789 19.7591 29.7475C19.6563 30.8175 19.7288 31.8856 19.9746 32.9547C20.1989 33.9286 20.5596 34.8496 21.0559 35.7205C21.2208 33.6246 21.0942 31.5146 20.6718 29.4173L20.6148 29.132C20.5503 28.0929 21.2601 27.1358 22.3054 26.9245C23.3834 26.7072 24.4372 27.3565 24.7461 28.3932L24.7848 28.5853C24.9979 29.6454 25.1442 30.7102 25.2292 31.7751L26.4297 30.5219C26.3643 29.9827 26.2848 29.4435 26.1881 28.9057"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6059 37.9541C15.8008 41.6011 13.9148 44.9839 11.1232 47.6046C10.8568 47.8538 10.8433 48.2721 11.0919 48.5391C11.2218 48.6783 11.3975 48.7486 11.5732 48.7486C11.7349 48.7486 11.8966 48.6895 12.0237 48.5701C14.6938 46.0642 16.5905 42.9068 17.5788 39.4776C17.2241 38.9885 16.8994 38.4811 16.6059 37.9541"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4881 28.0387C27.5657 28.4247 27.6339 28.8122 27.6956 29.2001L28.8344 28.0116L28.7867 27.7754C28.4605 26.1503 27.5227 24.7505 26.1465 23.8333C25.3587 23.3081 24.4806 22.9783 23.5708 22.8528C23.1974 23.1474 22.839 23.4707 22.4974 23.8267C22.3923 23.9326 22.29 24.0404 22.1904 24.1495C23.3245 24.0221 24.4554 24.2943 25.4171 24.9351C26.4278 25.6088 27.1386 26.6141 27.4376 27.7858L27.4881 28.0387Z"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6555 40.6707C28.8924 39.6078 29.077 38.5336 29.1999 37.4509C29.241 37.0879 28.9812 36.7604 28.6195 36.7187C28.2573 36.678 27.9312 36.9384 27.8896 37.301C27.7695 38.3569 27.5886 39.4049 27.3555 40.4416C27.5186 40.4819 27.6835 40.5189 27.8499 40.5508C28.1186 40.6028 28.3872 40.6426 28.6555 40.6707"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8492 45.2266C25.0576 47.1585 24.0646 49.0104 22.8838 50.7414C22.6782 51.0426 22.7553 51.4544 23.0553 51.6605C23.1693 51.7383 23.2992 51.7757 23.4272 51.7757C23.638 51.7757 23.844 51.6755 23.9716 51.4881C25.2501 49.6152 26.316 47.6054 27.1571 45.5086C26.7155 45.4346 26.2796 45.3404 25.8492 45.2266"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7462 31.0728C14.7046 29.0621 15.043 27.1447 15.7612 25.3186C16.3369 23.8523 17.1355 22.5134 18.149 21.2982C14.9845 23.1932 13.1911 26.9494 13.9598 30.7744L14.0523 31.1824C15.0084 36.6804 13.0126 42.3212 8.78735 45.9926C8.51211 46.2315 8.48221 46.6494 8.72006 46.9253C8.8509 47.0766 9.03407 47.1539 9.21912 47.1539C9.37192 47.1539 9.52566 47.1005 9.65043 46.9923C13.0486 44.0405 15.113 39.9105 15.5271 35.542C15.0397 34.1197 14.7771 32.6309 14.7462 31.0728"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.0374 25.711C30.2856 23.7968 28.8958 22.1858 27.0729 21.1664C27.0421 21.1491 27.0108 21.1374 26.9785 21.1256C26.7206 21.1987 26.4631 21.2821 26.207 21.3772C26.1972 21.3922 26.1851 21.4058 26.1762 21.4217C25.9991 21.7407 26.1131 22.1436 26.4313 22.3216C28.1669 23.2923 29.443 24.8926 30.0141 26.7796L31.0374 25.711Z"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5033 40.5314C32.0674 37.6906 32.2641 34.7795 32.086 31.8675L30.8225 33.1877C30.8804 35.7193 30.6449 38.242 30.1211 40.7071C30.43 40.6902 30.7393 40.6602 31.0487 40.6129C31.2015 40.5895 31.3529 40.5614 31.5033 40.5314"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8759 39.16C12.0076 38.8195 11.8394 38.4363 11.4997 38.3041C11.1604 38.172 10.7782 38.3407 10.6464 38.6813C9.82445 40.8044 8.46231 42.689 6.70716 44.1314C6.42586 44.3629 6.38427 44.7793 6.61511 45.0623C6.74548 45.2216 6.93473 45.304 7.12539 45.304C7.27212 45.304 7.42025 45.2553 7.54315 45.1546C9.47305 43.5683 10.9712 41.4954 11.8759 39.16"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1276 20.8543C30.6547 20.7831 30.1776 20.7433 29.6949 20.7433C29.4809 20.7433 29.2683 20.7527 29.0557 20.7663C30.3444 21.7618 31.4043 23.0613 32.1159 24.5843L33.0865 23.5715C32.5613 22.5643 31.8987 21.6526 31.1276 20.8543"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4967 39.4318C35.0108 36.0509 35.0463 32.6334 34.6047 29.2351L33.4192 30.4737C33.7234 33.5371 33.6285 36.6107 33.129 39.6506C33.1061 39.7892 33.1285 39.9237 33.1846 40.0413C33.5014 39.9195 33.815 39.7827 34.1238 39.6281C34.2496 39.5658 34.3738 39.5002 34.4967 39.4318"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6728 18.7986C20.706 18.7916 20.7401 18.7874 20.7738 18.7808C21.5499 18.199 22.3831 17.7001 23.2733 17.2841C22.3345 17.2395 21.3747 17.307 20.4116 17.5019C14.0906 18.7803 9.98497 24.9796 11.2593 31.3208L11.3429 31.6965C11.5499 32.9014 11.5892 34.1213 11.4602 35.3281C11.4214 35.6911 11.684 36.0172 12.0457 36.0561C12.4083 36.0959 12.7331 35.8317 12.7714 35.4686C12.928 34.0013 12.8583 32.5168 12.5649 31.0566L12.4873 30.708C11.5738 25.2059 15.1808 19.9094 20.6728 18.7986"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7696 44.7915C34.499 45.7008 34.1953 46.6021 33.8551 47.4931C33.7252 47.8347 33.8957 48.2169 34.2355 48.3472C34.3126 48.3767 34.3925 48.3912 34.4705 48.3912C34.7364 48.3912 34.9864 48.2291 35.0869 47.9654C35.5672 46.7061 35.9798 45.4272 36.3266 44.1338C36.1182 44.2364 35.9074 44.3352 35.6934 44.4289C35.3892 44.5619 35.0803 44.68 34.7696 44.7915"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8682 27.9154C36.4219 31.3802 36.4943 34.8825 36.0948 38.3304C36.3467 38.1196 36.5929 37.8966 36.8312 37.6586C37.0462 37.4431 37.2822 37.2759 37.5369 37.1508C37.8186 33.6907 37.6425 30.1889 37.0018 26.7311L35.8682 27.9154Z"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5549 22.0383C34.2221 21.8537 33.8862 21.6935 33.5479 21.5488C33.7558 21.8519 33.9535 22.1606 34.1338 22.4777L34.5549 22.0383Z"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.241 17.0297C18.1578 16.6086 19.1288 16.2919 20.1279 16.09C22.1447 15.6824 24.1881 15.7597 26.1176 16.2778C26.9886 16.0609 27.8816 15.9162 28.7966 15.8468C26.0367 14.5609 22.9227 14.1753 19.8671 14.7932C18.769 15.0158 17.7003 15.3634 16.691 15.8276C16.3601 15.9794 16.2153 16.372 16.3667 16.7041C16.5185 17.0363 16.9096 17.182 17.241 17.0297"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86218 31.603L9.78507 31.2479C8.97199 26.5454 10.7407 21.7919 14.4528 18.7679C14.7355 18.5374 14.7785 18.121 14.5486 17.8376C14.3192 17.5537 13.9038 17.5106 13.6215 17.741C9.4332 21.1529 7.49349 26.5656 8.55937 31.8668L8.63087 32.197C9.25704 35.9204 7.84021 39.6705 4.88507 42.0517C4.60096 42.2804 4.5561 42.6968 4.78414 42.9817C4.91405 43.1442 5.10517 43.2285 5.29816 43.2285C5.44349 43.2285 5.58881 43.1808 5.71078 43.0828C9.13975 40.3198 10.7299 35.9209 9.86218 31.603"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.15999 32.15L7.08336 31.792C6.95625 31.0776 6.87775 30.3529 6.84831 29.6319C6.83336 29.267 6.52728 28.9803 6.16233 28.9981C5.79878 29.0136 5.5156 29.3213 5.53102 29.6863C5.56794 30.5975 5.67822 31.5152 5.85906 32.4132L5.91794 32.6882C6.34971 35.3332 5.367 38.0246 3.31466 39.7687C3.03756 40.0048 3.00298 40.4217 3.23803 40.7004C3.36887 40.855 3.55438 40.9342 3.74177 40.9342C3.89224 40.9342 4.04364 40.8831 4.16747 40.7777C6.65766 38.6612 7.80438 35.3552 7.15999 32.15"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6664 27.9674C38.715 28.2958 38.9963 28.5319 39.3183 28.5319C39.3501 28.5319 39.3823 28.5296 39.4155 28.5244C39.7758 28.471 40.0244 28.1342 39.9711 27.773C39.8641 27.0497 39.736 26.3156 39.5903 25.5913L39.4888 25.1018L39.4814 25.1008C39.4225 24.8455 39.3575 24.5935 39.2874 24.3433L38.208 25.4709C38.2365 25.5984 38.2688 25.7239 38.2945 25.8523L38.3571 26.1525C38.4739 26.7569 38.5776 27.3659 38.6664 27.9674"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3229 12.085C15.8575 12.7858 12.7388 14.5055 10.3033 17.0582C7.92337 19.5519 6.35562 22.6616 5.7687 26.0495C5.70608 26.4093 5.94674 26.7518 6.30562 26.8141C6.66449 26.8773 7.00562 26.6361 7.0673 26.2758C7.60982 23.1474 9.05795 20.2761 11.2556 17.9727C13.5042 15.6163 16.3837 14.0286 19.5837 13.3812C24.0154 12.485 28.3939 13.5456 31.8332 15.9625C32.8351 16.1077 33.8196 16.3569 34.7883 16.7092C30.85 12.8312 25.1276 10.9105 19.3229 12.085"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.741 44.4154C37.6476 44.7682 37.8565 45.1308 38.2083 45.225C38.2653 45.24 38.3224 45.247 38.3784 45.247C38.67 45.247 38.9368 45.0516 39.0153 44.756C39.2705 43.7952 39.4892 42.8273 39.6761 41.8548C39.1611 42.3167 38.6233 42.7393 38.0611 43.1201C37.9611 43.5535 37.8555 43.9854 37.741 44.4154"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6444 30.6942C39.2808 30.7172 39.0051 31.0315 39.028 31.396C39.1439 33.2371 39.1327 35.0768 39 36.9057C39.4818 36.9567 39.9093 37.1333 40.2813 37.4369C40.4505 35.4047 40.4724 33.3598 40.3439 31.3126C40.3206 30.9482 40.007 30.6741 39.6444 30.6942"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.45708 32.6964L4.38512 32.3535C3.51316 27.5686 4.50615 22.7171 7.19727 18.6546C9.95288 14.4946 14.158 11.6599 19.0389 10.6728C21.0889 10.2582 23.1683 10.1894 25.2206 10.4672C25.5809 10.5168 25.9132 10.2624 25.9622 9.90031C26.0108 9.53818 25.7575 9.2051 25.3973 9.15638C23.1991 8.85843 20.972 8.93245 18.7781 9.37609C13.5524 10.4334 9.04914 13.4686 6.09914 17.9224C3.14867 22.377 2.10428 27.7171 3.15848 32.9597L3.20101 33.1644C3.43185 34.6359 2.97437 36.1495 1.95708 37.2495C1.70942 37.5179 1.72531 37.9363 1.9926 38.1846C2.11923 38.3026 2.28045 38.3607 2.44073 38.3607C2.61736 38.3607 2.794 38.2895 2.92437 38.149C4.27624 36.6859 4.8496 34.6476 4.45708 32.6964"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1749 24.4818H42.1665C40.5843 17.6313 35.5086 12.0856 28.8011 9.92832C28.4548 9.81682 28.0833 10.0084 27.9721 10.356C27.8609 10.7041 28.0516 11.0761 28.3988 11.188C32.3894 12.4712 35.7605 15.0398 38.0647 18.3889C38.7572 18.8629 39.4137 19.3956 40.0305 19.9943L40.0922 20.0566C40.5432 20.5902 40.7684 21.1641 40.7684 21.7787C40.7684 22.2336 40.645 22.6655 40.3992 23.0749C40.6413 23.8006 40.8422 24.545 40.9955 25.3063L41.0763 25.6904C41.9432 30.2055 42.0974 34.7881 41.5352 39.3191C41.4904 39.6817 41.7464 40.012 42.1077 40.0569C42.1357 40.0602 42.1633 40.0621 42.1899 40.0621C42.5179 40.0621 42.8025 39.8171 42.8436 39.4826C43.4394 34.6813 43.2544 29.8237 42.2936 25.0444L42.1749 24.4818Z"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7188 45.7182C29.7103 45.7186 29.7024 45.721 29.6945 45.721C29.6566 45.721 29.6188 45.72 29.5809 45.72C29.5711 45.72 29.5613 45.72 29.5515 45.72C29.2435 45.72 28.9389 45.7097 28.636 45.6915C28.0122 47.3021 27.2575 48.87 26.3748 50.3794C26.1907 50.6947 26.2959 51.0995 26.6103 51.2845C26.7146 51.3463 26.8295 51.3754 26.9431 51.3754C27.1692 51.3754 27.3898 51.2587 27.5122 51.0484C28.5146 49.3347 29.3566 47.5485 30.0375 45.7116C29.9314 45.7149 29.8253 45.7172 29.7188 45.7182"
        fill="#676767"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0423 49.3637C29.8829 49.6921 30.0189 50.0879 30.346 50.2481C30.4395 50.2936 30.5381 50.3151 30.6348 50.3151C30.8787 50.3151 31.1133 50.1783 31.2278 49.9432C31.9582 48.4394 32.5843 46.8817 33.104 45.2927C32.617 45.4117 32.1203 45.5072 31.6161 45.577C31.1647 46.8677 30.639 48.1344 30.0423 49.3637"
        fill="#676767"
      />
    </svg>
  )
}
