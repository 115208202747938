import { LosseLink, LossePlaatjie, useLocation } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import Content from '~/components/elements/Content'
import Microcopy from '~/components/elements/Microcopy'
import type { Solution } from '~/graphql/types'
import type { Page_Flexcontent_Flex_Solutions, Maybe } from '~/graphql/types'

export default function SolutionsOverview({ fields }: { fields: Page_Flexcontent_Flex_Solutions }) {
  const [currentPostIndex, setCurrentPostIndex] = useState(-1)
  const [highlightPostIndex, setHighlightPostIndex] = useState(-1)
  const { hash } = useLocation()
  const initialPostAmount = 9

  function findHashIndexByValue(arr: Array<any>, key: string, value: string) {
    const lowercaseValue = value.toLowerCase()

    for (let i = 0; i < arr.length; i++) {
      const lowercaseName = arr[i].node[key].toLowerCase().replace(/ /g, '-')
      if (`#${lowercaseName}` === lowercaseValue) {
        return i
      }
    }
    return -1
  }

  useEffect(() => {
    if (hash) {
      const hashIndex = findHashIndexByValue(fields.posts?.edges || [], 'title', hash)

      if (hashIndex !== -1) {
        setCurrentPostIndex(hashIndex)
        setHighlightPostIndex(hashIndex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  const posts = fields.posts?.edges

  const topPosts = posts?.slice(0, initialPostAmount)
  const restPosts = posts?.slice(initialPostAmount)

  return (
    <section data-component="SolutionsOverview" id={fields.sectionId ?? undefined} className="py-12 lg:py-24">
      <div className="container">
        <div className="grid grid-cols-5 gap-4">
          <div className="col-span-5 lg:col-span-2">
            <Content className="children-headings:text-4xl lg:children-headings:text-6xl xl:children-headings:text-7xl text-eo-buizel">
              {fields.title}
            </Content>
          </div>
          <div className="col-span-5 lg:col-span-3">
            <Content className="children-headings:text-xl lg:children-headings:text-3xl text-eo-stakataka">{fields.description}</Content>
            {(fields.microcopy || fields.link) && (
              <div className="mt-6 flex flex-col items-center lg:items-start justify-center lg:justify-start">
                {fields.link && <LosseLink src={fields.link} className="btn--blue" />}
                {fields.microcopy && (
                  <div>
                    <Microcopy text={fields.microcopy} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-6 mt-20 gap-x-6 gap-y-6 lg:gap-y-12">
          {topPosts?.map((edge, index) => {
            const post = edge.node as Solution

            return (
              <SolutionPost
                key={post.title}
                index={index}
                readMoreText={fields.readMoreText}
                readLessText={fields.readLessText}
                setCurrentPostIndex={setCurrentPostIndex}
                setHighlightPostIndex={setHighlightPostIndex}
                expanded={currentPostIndex === index}
                highlighted={highlightPostIndex === index}
                post={post}
              />
            )
          })}
          {fields.showCta && (
            <div className="col-span-6 lg:mb-24 lg:mt-16">
              <div className="bg-eo-buizel rounded-2xl shadow-lg grid grid-cols-6 p-4 lg:p-9">
                <div className="col-span-6 lg:col-span-1 relative">
                  <LossePlaatjie
                    maxwidth={500}
                    src={fields.cta?.image}
                    className="relative lg:absolute lg:-top-1/2 max-lg:h-[250px] w-auto mx-auto"
                  />
                </div>
                <div className="col-span-6 lg:col-span-3 flex items-center mt-6 lg:mt-0">
                  <Content className="text-white lg:px-12 max-lg:text-center children-headings:font-montserrat lg:children-headings:text-xl children-headings:mb-2">
                    {fields.cta?.description}
                  </Content>
                </div>
                <div className="col-span-6 lg:col-span-2 flex md:max-lg:justify-center items-center mt-6 lg:mt-0">
                  <LosseLink src={fields.cta?.link} className="btn--blue" />
                </div>
              </div>
            </div>
          )}
          {restPosts?.map((edge, index) => {
            const post = edge.node as Solution

            const theRealIndex = index + initialPostAmount

            return (
              <SolutionPost
                key={post.title}
                index={theRealIndex}
                readMoreText={fields.readMoreText}
                readLessText={fields.readLessText}
                setCurrentPostIndex={setCurrentPostIndex}
                setHighlightPostIndex={setHighlightPostIndex}
                expanded={currentPostIndex === theRealIndex}
                highlighted={highlightPostIndex === theRealIndex}
                post={post}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

function SolutionPost({
  post,
  readMoreText,
  readLessText,
  index,
  setCurrentPostIndex,
  setHighlightPostIndex,
  expanded,
  highlighted
}: {
  post: Solution
  readMoreText?: Maybe<string | undefined>
  readLessText?: Maybe<string | undefined>
  index: number
  setCurrentPostIndex: Dispatch<SetStateAction<number>>
  setHighlightPostIndex: Dispatch<SetStateAction<number>>
  expanded: boolean
  highlighted: boolean
}) {
  const scrollId = post.title?.toLowerCase().replace(/ /g, '-')
  const excludeLink = post.advancedPosttypeSettings?.excludePageFromSite

  return (
    <div
      id={scrollId ?? undefined}
      className={clsx(
        highlighted ? 'border-eo-buizel/50' : 'border-transparent',
        'border-4 col-span-6 md:col-span-3 h-fit lg:col-span-2 flex flex-col items-center shadow-lg bg-white'
      )}
    >
      {excludeLink ? (
        <div className="p-4 lg:p-8">
          <div className="flex items-center mb-6">
            {post.featuredImage && (
              <LossePlaatjie maxwidth={96} src={post.featuredImage?.node} className="h-16 w-16 lg:w-24 lg:h-24 object-contain" />
            )}
            <h3 className="text-xl ml-4">{post.title}</h3>
          </div>

          {expanded ? <Content>{post.recap?.resume}</Content> : <Content className="line-clamp-3">{post.recap?.resume}</Content>}

          <div className="flex justify-end mt-4 lg:mt-10">
            <button
              className={clsx('btn--blue--transparent', expanded && 'after:-rotate-90 after:transition-transform')}
              onClick={() => {
                if (expanded) {
                  setCurrentPostIndex(-1)
                } else {
                  setHighlightPostIndex(-1)
                  setCurrentPostIndex(index)
                }
              }}
            >
              {expanded ? readLessText : readMoreText}
            </button>
          </div>
        </div>
      ) : (
        <LosseLink to={post.uri} className="p-4 lg:p-8">
          <div className="flex items-center mb-6">
            <LossePlaatjie maxwidth={96} src={post.featuredImage?.node} className="h-16 w-16 lg:w-24 lg:h-24 object-contain" />
            <h3 className="text-xl ml-4">{post.title}</h3>
          </div>
          <Content className="line-clamp-3">{post.recap?.resume}</Content>
          <div className="flex justify-end mt-4 lg:mt-10">
            <span className="btn--blue--transparent">{readMoreText}</span>
          </div>
        </LosseLink>
      )}
    </div>
  )
}
