import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function PostsHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <section data-component="PostsHighlighted" id={fields.sectionId ?? undefined} className="py-10 lg:py-20">
      <div className="container">
        {fields.title && fields.description && (
          <div className="grid grid-cols-3 items-center gap-3 lg:gap-14">
            <div className="col-span-3 lg:col-span-1">
              <Content className="children-headings:text-4xl lg:children-headings:text-7xl text-eo-buizel">{fields.title}</Content>
            </div>
            <div className="col-span-3 lg:col-span-2">
              <Content>{fields.description}</Content>
            </div>
          </div>
        )}
        {((fields.title && !fields.description) || (fields.description && !fields.title)) && (
          <Content className="children-headings:text-4xl lg:children-headings:text-7xl children-headings:text-eo-buizel">
            {fields.title ?? fields.description}
          </Content>
        )}
        <div className="lg:px-24 mt-8 lg:mt-16">
          {fields.highlighted?.map((edge) => {
            return (
              <LosseLink to={edge?.uri} key={edge?.title} className="grid grid-cols-6 rounded-2xl shadow-lg mb-12 overflow-hidden bg-white">
                <div className="col-span-6 xs:col-span-2">
                  <LossePlaatjie
                    maxwidth={400}
                    src={edge?.featuredImage?.node}
                    className="xs:max-h-[325px] md:max-h-[300px] h-full w-full lg:max-h-none object-cover"
                  />
                </div>
                <div className="col-span-6 xs:col-span-4">
                  <div className="flex flex-col h-full px-6 lg:px-20 pt-4 lg:pt-12 pb-4 lg:pb-7">
                    <span className="inline-block font-semibold mb-2 lg:mb-4 max-lg:text-sm">{edge?.recap?.subtitle}</span>
                    <h2 className="text-xl lg:text-2xl text-eo-buizel lg:font-semibold lg:pr-24 xl:pr-36">{edge?.title}</h2>
                    <div className="flex justify-end mt-auto">
                      <span className="btn--blue--transparent">{fields.readMoreText}</span>
                    </div>
                  </div>
                </div>
              </LosseLink>
            )
          })}
          <div className="flex flex-col items-center justify-center">
            <span className="inline-block mb-7 text-xl font-bold text-center">{fields.microcopy}</span>
            <LosseLink src={fields.link} className="btn--blue" />
          </div>
        </div>
      </div>
    </section>
  )
}
