import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import Microcopy from '~/components/elements/Microcopy'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentDefault" id={fields.sectionId ?? undefined} className="my-10 lg:my-20">
      <div className="container">
        <div
          className={clsx(
            fields.columnAlignment === 'left' && 'mr-auto',
            fields.columnAlignment === 'center' && 'mx-auto',
            fields.columnAlignment === 'right' && 'ml-auto',
            'lg:w-3/4'
          )}
        >
          <Content
            className={clsx(
              fields.titleColor === 'orange' && 'children-h2:text-eo-buizel',
              fields.titleColor === 'blue' && 'children-h2:text-eo-whiscash',
              fields.titleColor === 'gray' && 'children-h2:text-eo-beautifly',
              fields.titleFontsize === 'default' &&
              'children-h3:text-lg lg:children-h3:text-xl children-h2:text-xl sm:children-h2:text-2xl lg:children-h2:text-3xl lg:children-h2:!leading-[3rem]',
              fields.titleFontsize === 'large' &&
              'children-h2:text-4xl lg:children-h2:text-7xl children-h3:text-eo-stakataka children-h3:text-2xl lg:children-h3:text-4xl',
              fields.titleFontfamily === 'montserrat' &&
              'children-headings:!mb-1 children-headings:!font-montserrat children-strong:!font-semibold children-headings:!font-semibold',
              fields.titleFontsize === 'default' && fields.titleFontfamily === 'montserrat' && 'lg:children-h2:!text-4xl',
              'children-headings:mb-3 lg:children-headings:mb-12'
            )}
          >
            {fields?.description}
          </Content>
          <div className={clsx(fields.columnAlignment === 'center' && 'justify-center', 'flex')}>
            {fields.links?.map((edge) => {
              return (
                <div
                  key={edge?.link?.title}
                  className={clsx(
                    fields.columnAlignment === 'center' ? 'lg:items-center' : 'lg:items-start',
                    'flex flex-col items-center justify-center lg:justify-start mt-7 lg:mt-14'
                  )}
                >
                  <div>
                    <LosseLink className="btn--blue" key={edge?.link?.title} src={edge?.link} />
                  </div>
                  {edge?.microcopy && (
                    <Microcopy
                      className={clsx(fields.columnAlignment === 'center' ? 'lg:!text-center lg:px-20' : 'lg:!text-left lg:pr-40')}
                      text={edge?.microcopy}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
