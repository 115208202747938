import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import SliderDefault from './SliderDefault'
import SliderOverflow from './SliderOverflow'

export default function SliderShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: SliderDefault,
    overflow: SliderOverflow
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
