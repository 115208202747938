/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { LosseLink, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import { AnimatePresence, motion } from 'framer-motion'
import Logo from '~/components/elements/Logo'
import Fingerprint from '~/components/elements/Fingerprint'
import useCustomLayout from '~/hooks/useCustomLayout'
import clsx from 'clsx'
import HeaderTopmenu from './HeaderTopmenu'
import SearchInput from '~/components/elements/SearchInput'

export default function HeaderMobile() {
  const { setScrollable } = useLosseLayout()
  const { sticky } = useCustomLayout()
  const location = useLocation()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setOpen(false)
    }
  }, [location.pathname, location.hash, location.search])

  useEffect(() => {
    const { documentElement } = document

    if (isOpen) {
      documentElement.classList.add('overflow-hidden')
    } else {
      documentElement.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <div className="relative z-[99] block xl:hidden">
      <button
        type="button"
        onClick={() => {
          setOpen(!isOpen)

          setScrollable(false)
        }}
        className="z-10 h-12 w-8"
        title="Open menu"
      >
        <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
        <div className="mx-auto my-[5px] h-[2px] w-full bg-white" />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ y: '200%' }}
            animate={{ y: 0 }}
            exit={{ y: '200%' }}
            transition={{ bounce: 0, duration: 0.5 }}
            className={clsx(
              sticky ? '-top-4' : 'top-0',
              'fixed left-0 z-50 flex h-[100dvh] w-screen items-center justify-center bg-gradient-primary'
            )}
          >
            <div className="absolute -left-32 bottom-[2vh]">
              <Fingerprint className="w-1/2 h-auto opacity-50" />
            </div>
            <div className="absolute left-0 right-0 flex justify-center top-9">
              <LosseLink to="/" aria-label="Home">
                <Logo />
              </LosseLink>
            </div>
            <div
              className="absolute bottom-9 flex justify-center "
              role="button"
              onClick={() => {
                setOpen(!isOpen)
                setScrollable(true)
              }}
              aira-label="Close menu"
            >
              <div className="relative ml-8 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-white after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-white " />
            </div>
            <ul className="relative z-[99] w-[90%]">
              <HeaderMenu />
              <HeaderTopmenu />
              <div className="mt-6 mx-12 md:hidden">
                <SearchInput />
              </div>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
